@keyframes loadingShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.nft_gallery,
.nft_storefront {
  &.disconnected {
    margin: 21px 25px;

    .content {
      padding-bottom: 0;
    }
  }

  .content {
    padding-top: 15px;

    .title {
      margin-bottom: 25px;
      font-size: 20px;
      color: #000;
    }

    .error {
      margin-bottom: 25px;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: var(--biositeColorProfile-text);
        text-align: center;
        text-transform: uppercase;
        background: var(--biositeColorProfile-buttonBackground);
      }
    }

    ul {
      @item-width-height: 140px;
      display: flex;
      padding: 0 0 15px 0;
      margin: 0;
      overflow-x: auto;
      list-style: none;

      &.loader {
        display: flex;

        li {
          cursor: default;

          div {
            width: @item-width-height;
            height: @item-width-height;
            background: #f6f7f8;
            background: linear-gradient(
              to right,
              #6a6a6a 8%,
              #8a8a8a 38%,
              #6a6a6a 54%
            );
            background-size: 1000px 640px;
            border-radius: 13px;
            animation-name: loadingShimmer;
            animation-duration: 1.8s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
          }
        }
      }

      li {
        margin-right: 10px;
        cursor: pointer;

        img {
          width: @item-width-height;
          height: @item-width-height;
          object-fit: cover;
        }

        video {
          width: @item-width-height;
          height: @item-width-height;
          object-fit: cover;
        }

        &.view_more {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: @item-width-height;
            height: @item-width-height;
            color: var(--biositeColorProfile-background);
            background: var(--biositeColorProfile-textDim);
          }
        }
      }
    }

    .placeholder {
      padding: 12px;
      margin: 20px 14px;
      text-align: left;
      background: #fff;
      border: 1px solid #e8ebea;
      border-radius: 2.5px;

      .title {
        display: flex;
        padding-left: 32px;
        margin-bottom: 7px;
        font-size: 16px;
        color: #ed8e3a;
        background: data-uri('public/icons/common/warning_triangle.svg')
          no-repeat top left;
      }

      .description {
        margin-top: 0px;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #bdbdbd;
      }
    }
  }
}
