@import (reference) '../mixins.less';

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-6px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.advanced-02 {
  .advanced-link();
  max-width: 486px;
  padding: 24px 36px;
  text-align: center;
  border-radius: 8px;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:not(.hasThumbnail) {
    min-height: 486px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  > * {
    transition: transform 0.3s ease-out;
    transform: translateY(-3px);
  }

  &.isVisible {
    opacity: 1;

    > * {
      opacity: 1;
      transform: translateY(0);

      each(range(1, 4), {
        &:nth-child(@{value}) {
          @delayFactor: (@value / 15);

          transition-delay: ~"@{delayFactor}s";
        }
      });
    }
  }

  .counter {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 1;
  }

  .name {
    display: flex;
    flex-grow: 1;
    // Need the `!important` because of `AutoTextSize`
    align-items: center !important;
    justify-content: center;
    width: 100%;
    margin-bottom: 14px;
    font-size: 72px;
    line-height: 1.15;
    text-transform: uppercase;

    &.resizer {
      position: absolute;
      visibility: hidden;
    }
  }

  .thumbnail {
    max-width: 100%;
    aspect-ratio: 1;
    margin-bottom: 16px;
    object-fit: cover;

    &.isIcon {
      border: 1px solid var(--biositeColorProfile-buttonTextDim);
    }
  }

  .url {
    margin-top: 24px;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    color: var(--biositeColorProfile-buttonTextDim);
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
