@import 'BioSite/styles/template/common/colors';

.notificationBanner {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  opacity: 0;
  transition:
    transform 400ms,
    opacity 400ms;
  transform: translateY(-12rem);

  &.active {
    opacity: 1;
    transition-delay: 500ms;
    transform: translateY(0);
  }

  .notification {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 33.5rem;
    min-height: 4.5rem;
    padding: 0.8rem 1.6rem;
    margin-top: 2rem;
    font-family: 'Clarkson', sans-serif;
    font-size: 1.4rem;
    background-color: white;
    border-radius: 0.6rem;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.08);

    &.error .title {
      color: @errorRed;
    }
  }

  &.enter {
    opacity: 0;
    transform: scale(0.95);
  }

  &.enter-active {
    opacity: 1;
    transition:
      opacity 200ms,
      transform 200ms;
    transform: scale(1);
  }

  &.exit {
    height: 4.6rem;
    opacity: 1;
    transform: scale(1);
  }

  &.exit-active {
    height: 0;
    margin-top: 0;

    opacity: 0;
    transition:
      opacity 200ms,
      transform 200ms,
      height 200ms,
      margin-top 200ms;
    transform: scale(0.95);
  }

  .message {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
  }

  .subtitle {
    font-size: 1rem;
    line-height: 1.2rem;
    color: @greyText;
  }

  .closeIcon {
    color: @greyBackground;
  }
}
