.variant3_container {
  position: relative;
  height: 100%;
  transform-origin: center center;

  .spinningCircleWrapper {
    position: absolute;
    top: 19px;
    left: 20px;
  }
}
