@import 'mixins.less';

:global(.body_section.links.cards-01),
:global(.body_section.links.cards-02),
:global(.body_section.links.cards-03),
:global(.body_section.links.cards-04),
:global(.body_section.links.cards-05),
:global(.body_section.links.cards-06),
:global(.body_section.links.cards-07),
:global(.body_section.links.cards-08) {
  filter: drop-shadow(0 6px 40px rgba(0, 0, 0, 0.06));
}

.rightSide {
  margin-right: 22px;

  svg {
    display: block;
  }
}

.cards-01 {
  .cards();
  display: flex;
  flex-direction: column;
  min-height: 72px;
  margin-bottom: 12px;
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 20px;

  .thumbnail {
    display: block;
    width: 100%;
    height: 366px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .textContainer {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    gap: 22px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 72px;
    padding: 20px 0;

    .text {
      display: flex;
      flex: 1;
      column-gap: 10px;
      align-items: center;
      font-size: 16px;
      line-height: 1.2;
      color: var(--biositeColorProfile-buttonText);
    }

    .name {
      padding-left: 22px;
      text-align: left;
    }

    .price {
      padding-right: 10px;
    }

    .rightSide {
      color: var(--biositeColorProfile-buttonText);
    }
  }
}

.cards-02 {
  .cards();
  display: flex;
  flex-direction: column-reverse;
  min-height: 80px;
  margin: 0 -22px 12px -22px;
  background: var(--biositeColorProfile-buttonBackground);

  .thumbnail {
    display: block;
    width: 100%;
    height: 412px;
    object-fit: cover;
  }

  .textContainer {
    display: flex;
    flex: 1;
    gap: 22px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--biositeColorProfile-buttonText);

    .text {
      display: flex;
      flex: 1;
      column-gap: 10px;
      align-items: center;
      font-size: 16px;
      line-height: 1.2;
    }

    .name {
      padding-left: 22px;
      text-align: left;
      vertical-align: middle;
    }

    .price {
      padding-right: 10px;
    }
  }
}

:global(.body_section.links.cards-03),
:global(.body_section.links.cards-04) {
  @media (max-width: 990px) {
    padding: 10px 0;
  }
}

// Repeat the previews rules because in preview the media query is not true

:global(.body_section.links.cards-03.isPreviewMode),
:global(.body_section.links.cards-04.isPreviewMode) {
  padding: 10px 0;
}

.cards-03,
.cards-04 {
  .cards();
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 200px;
  min-height: 200px;
  padding: 16px;
  overflow: hidden;
  color: var(--biositeColorProfile-buttonText);
  text-align: start;
  background-color: var(--biositeColorProfile-buttonBackground);
  border-radius: 8px;

  &.hasThumbnail {
    color: var(--biositeColorProfile-linkThumbnailText);
  }

  &:not(.isLast) {
    margin-right: 8px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    .thumbnailOverlayMixin();

    &::after {
      border-radius: 8px;
    }

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textContainer {
    z-index: 2;
    width: 100%;
    font-size: 14px;
    line-height: 1.2;

    .text {
      width: 100%;

      &.hasPrice {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .price {
          margin-left: 5px;
        }
      }
    }

    .name {
      overflow: hidden;
    }
  }
}

.cards-04 {
  width: 243px;
  min-height: 324px;
  padding: 22px;
  border-radius: 16px;

  &:not(.isLast) {
    margin-right: 12px;
  }

  .textContainer {
    font-size: 16px;
    line-height: 1.2;
  }

  .rightSide {
    position: absolute;
    top: 12px;
    right: 12px;
    margin-right: 0;
  }
}

.cards-05 {
  .cards();
  display: flex;
  flex-direction: column;
  min-height: 72px;
  margin-bottom: 12px;
  color: var(--biositeColorProfile-buttonText);
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 16px;

  svg {
    color: currentColor;
  }

  &.hasThumbnail {
    position: relative;
    color: var(--biositeColorProfile-linkThumbnailText);

    .textContainer {
      position: absolute;
      bottom: 9px;
      z-index: 2;
    }
  }

  .overlay {
    position: relative;

    .thumbnailOverlayMixin();

    &::after {
      border-radius: 12px;
    }
  }

  .thumbnail {
    display: block;
    width: 100%;
    height: 276px;
    object-fit: cover;
    border-radius: 16px;
  }

  .textContainer {
    display: flex;
    flex: 1;
    gap: 22px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .text {
      display: flex;
      flex: 1;
      column-gap: 10px;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 1.285;

      &.hasPrice {
        overflow: hidden;
      }
    }

    .name {
      padding-left: 24px;
      overflow: hidden;
      text-align: left;
      vertical-align: middle;
    }

    .price {
      padding-right: 10px;
    }
  }
}

.cards-06 {
  .cards();
  display: flex;
  flex-direction: column;
  min-height: 64px;
  margin-bottom: 12px;
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 16px;

  &.hasThumbnail {
    position: relative;

    .textContainer {
      position: absolute;
      bottom: -16px;
      width: calc(100% - 8px);
      min-height: 64px;
      margin-right: 4px;
      margin-left: 4px;
      background-color: var(--biositeColorProfile-buttonBackgroundSolid);
      border-radius: 12px;

      .text {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  .thumbnail {
    display: block;
    width: 100%;
    height: 366px;
    object-fit: cover;
    border-radius: 16px;
  }

  .textContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .text {
      display: flex;
      flex: 1;
      gap: 22px;
      align-items: center;
      justify-content: space-around;
      margin-right: 22px;
      margin-left: 22px;
      font-size: 16px;
      line-height: 14px;
      color: var(--biositeColorProfile-buttonText);

      &.hasPrice {
        justify-content: space-between;

        .name {
          text-align: left;
        }
      }
    }

    .name {
      text-align: center;
      vertical-align: middle;
    }
  }

  .rightSide {
    display: none;
  }
}

:global(.body_section.links.cards-07) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}

.cards-07 {
  .cards();
  display: flex;
  flex-direction: column;
  min-height: 192px;
  color: var(--biositeColorProfile-buttonText);
  text-align: start;
  background-color: var(--biositeColorProfile-buttonBackground);
  border-radius: 8px;

  .overlay {
    box-sizing: border-box;
    padding: 3px 3px 0;
  }

  .thumbnail {
    width: 100%;
    aspect-ratio: 1.38;
    object-fit: cover;
    border-radius: 6px;
  }

  .textContainer {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    width: 100%;
    min-height: 48px;
    padding: 12px;
  }

  .text {
    display: flex;
    flex: 1;
    gap: 5px;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1.4;

    &.hasPrice {
      justify-content: space-between;
    }
  }

  .name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

:global(.body_section.links.cards-08) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 0;
  grid-gap: 12px;
}

.cards-08 {
  .cards();
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-height: 64px;
  margin: auto;
  overflow: hidden;
  color: var(--biositeColorProfile-buttonText);
  text-align: start;
  background-color: var(--biositeColorProfile-buttonBackground);
  // `!important` because it needs to override potential template animations
  border-top: none !important;
  border-radius: 12px;

  &.isMasonryToStretch {
    .overlay {
      height: 100%;
    }

    .thumbnail {
      height: 100%;
      object-fit: cover;
    }
  }

  &.hasThumbnail {
    position: relative;
    color: var(--biositeColorProfile-linkThumbnailText);

    .textContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
      align-items: flex-end;
      padding: 0;

      .text {
        box-sizing: border-box;
        flex-grow: 0;
        max-height: 100%;
      }
    }
  }

  &:not(.hasThumbnail) {
    .textContainer {
      height: 100%;
    }
  }

  .overlay {
    .thumbnailOverlayMixin();

    &::after {
      border-radius: 12px;
    }
  }

  .thumbnail {
    width: 100%;
  }

  .textContainer {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;

    .text {
      flex-grow: 1;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 1.2;

      &.hasPrice {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 7px;
        align-items: flex-end;
        align-items: center;
      }
    }
  }
}
