@import 'BioSite/styles/template/common/common';
@import 'BioSite/styles/sizes';
@import 'BioSite/styles/vars';
@import 'BioSite/styles/template/common/colors.less';

.card {
  font-family: var(--secondary-font);
  background-color: white;
  border-radius: @sectionCardBorderRadius;
  box-shadow: @sectionShadow;
}

.content {
  padding: 1.4rem 1.8rem;
}

.title,
.subtitle {
  min-height: 1.4rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.title {
  font-weight: 700;
  color: var(--biositeColorProfile-buttonText);
  text-align: left;
  word-break: break-word;
  user-select: none;
}

.subtitle {
  margin-top: 1.2rem;
  color: @greyText;
  text-align: left;
}

.additionalNotes {
  margin: 1.2rem 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: @greyText;
  text-align: left;
  word-break: break-word;
}

.divider {
  margin: 0;
  border: 0.1rem solid @lightGreyText;
  border-bottom: none;
}

.buttons {
  text-align: center;

  button {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 6rem;
    color: var(--biositeColorProfile-buttonText);
    text-transform: uppercase;
  }
}
