@import (reference) '../mixins.less';

.advanced-04 {
  .advanced-link();
  position: relative;
  padding: 22px;
  border-radius: 16px;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;

  &:nth-child(even) {
    transform: translateX(20px);
  }

  &:nth-child(odd) {
    transform: translateX(-20px);
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:not(.hasThumbnail) {
    min-height: 380px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  &.isVisible {
    opacity: 1;
    transform: translateX(0);
  }

  &.hasThumbnail {
    min-height: 488px;
    color: var(--biositeColorProfile-linkThumbnailText);
  }

  .header {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
  }

  .thumbnailWrapper {
    .thumbnail-overlay();
    display: flex;
    align-items: center;
    justify-content: center;

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon {
      width: 60%;
    }
  }

  .name {
    z-index: 2;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    font-size: 28px;
    line-height: 1.14;
  }

  .url {
    z-index: 2;
    margin-top: 10px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
