.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.pathLarge {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash-large 1.4s ease-in-out infinite;
  animation: dash-large 1.4s ease-in-out infinite;
}

@-webkit-keyframes dash-large {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes dash-large {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.pathSmall {
  stroke-dasharray: 80;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash-small 1.4s ease-in-out infinite;
  animation: dash-small 1.4s ease-in-out infinite;
}

@-webkit-keyframes dash-small {
  0% {
    stroke-dashoffset: 80;
  }

  50% {
    stroke-dashoffset: 23;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 80;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash-small {
  0% {
    stroke-dashoffset: 80;
  }

  50% {
    stroke-dashoffset: 23;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 80;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
