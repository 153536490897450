.monetizedModal {
  display: flex;
  flex: 1;
  flex-direction: column;

  .image {
    width: 100%;
    object-fit: cover;
  }

  .content {
    padding: 24px 16px 16px 16px;
    text-align: center;
  }

  .title {
    margin: 0;
    margin-bottom: 1.263rem;
    font-family: var(--secondary-font);
    font-size: 19px;
    font-weight: 700;
    line-height: 22.8px;
    color: var(--biositeColorProfile-buttonText);
    text-align: center;
  }

  .description {
    margin: 0;
    margin-bottom: 1.714rem;
    font-family: var(--secondary-font);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--biositeColorProfile-buttonTextDim);
    text-align: center;
  }

  .continueButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    font-family: var(--primary-font);
    font-size: 16px;
    font-weight: 500;
    color: var(--biositeColorProfile-buttonTextText);
    text-transform: capitalize;
    cursor: pointer;
    background: var(--biositeColorProfile-buttonText);
    border: none;
    border-radius: 2px;
  }
}
