@color: #9c9c9c;

.timezone {
  font-family: 'Clarkson', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: @color;
  text-align: left;
  text-transform: uppercase;
}

.availableTimes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
}

.noAvailableTimes {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  font-family: 'Clarkson', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: @color;
  text-transform: uppercase;
}
