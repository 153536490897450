@import 'BioSite/styles/template/common/colors';
@import 'BioSite/styles/template/common/mixins';
@import 'BioSite/styles/template/common/common';
@import 'BioSite/styles/vars';

.progress {
  width: 100%;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: var(--biositeColorProfile-buttonText);
  }

  .progressBar {
    width: 100%;
    height: 8px;
    margin: 10px 0;
    -webkit-appearance: none;
  }

  ::-webkit-progress-bar {
    background: var(--biositeColorProfile-buttonTextDim);
    border-radius: 2px;
  }

  ::-webkit-progress-value {
    background: var(--biositeColorProfile-buttonText);
    border-radius: 2px;
  }

  .total {
    font-size: 15px;
    font-weight: normal;
    color: var(--biositeColorProfile-buttonTextDim);

    .current {
      font-size: 18px;
      font-weight: 500;
      color: var(--biositeColorProfile-buttonText);
    }
  }
}

.crowdfunding {
  .content {
    text-align: left;
  }

  .title {
    width: 100%;
    max-width: unset !important;
  }
}

.monetization {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--biositeColorProfile-buttonBackground);
  border-radius: @sectionBorderRadius;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  transition: border @duration-short;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: @sectionContentPadding;
    margin-top: 0;
    transition:
      padding @duration-short,
      margin-top @duration-short,
      width @duration-short,
      background @duration-short;

    &.has_image {
      border-top: none !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    & > .description {
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      color: var(--biositeColorProfile-buttonTextDim);
      word-break: break-word;
      opacity: 1;
      transition:
        max-height @duration-short,
        opacity @duration-short,
        width 0s;
    }

    & > .productPrice {
      margin-top: 0.857rem;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.286rem;
      color: var(--biositeColorProfile-buttonText);
      text-align: center;
    }

    & > .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 0;
      min-height: 22px;
      margin-bottom: 8px;
      margin-left: 0;
      font-size: 18px;
      line-height: 22px;
      color: var(--biositeColorProfile-buttonText);
      word-break: break-word;
      user-select: none;
      transition:
        font-size @duration-short,
        line-height @duration-short,
        font-weight @duration-short,
        margin @duration-short,
        min-width @duration-short,
        min-height @duration-short,
        left @duration-short,
        color @duration-short;
    }
  }

  .payments {
    width: 100%;
    margin-top: 1.6rem;
  }

  .sectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: @sectionImageMaxHeight;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
    }
  }

  :global(.clickable-element) {
    width: 100%;
  }

  .button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    margin-top: 16px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 54px;
    color: var(--biositeColorProfile-buttonText);
    border: 2px solid currentColor;
    border-radius: 6px;
    opacity: 1;
    transition:
      height @duration-short,
      margin @duration-short,
      opacity @duration-short,
      width 0s;

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    &.placeholder {
      color: #666;
      background: #e8ebea;
      border: 1px dashed #979797;
    }

    &.tip {
      position: relative;
      display: block;
      overflow: hidden;
      color: #fff;
      border: none;

      &.show_input {
        height: auto;
      }

      .text {
        display: flex;
        justify-content: center;
        color: var(--biositeColorProfile-buttonTextText);
        background-color: var(--biositeColorProfile-buttonText);
        border: 2px solid var(--biositeColorProfile-buttonText);
        border-radius: 2.5px;

        .loader {
          display: none;
        }

        &:not(.buttonLoading) {
          cursor: pointer;
        }

        &.buttonLoading {
          background: var(--biositeColorProfile-text);
        }
      }
    }

    &.venmo {
      color: #008cff;

      .icon {
        background: data-uri('public/icons/support/venmo.svg') no-repeat center;
      }
    }

    &.cashapp {
      color: #00d632;

      .icon {
        background: data-uri('public/icons/support/cashapp.svg') no-repeat
          center;
      }
    }

    &.patreon {
      color: #ff424d;

      .icon {
        background: data-uri('public/icons/support/patreon.svg') no-repeat
          center;
      }
    }

    &.paypal {
      color: #253b80;

      .icon {
        background: data-uri('public/icons/support/paypal.svg') no-repeat center;
      }
    }

    &.bandcamp {
      color: #1da0c3;

      .icon {
        background: data-uri('public/icons/support/bandcamp.svg') no-repeat
          center;
      }
    }

    &.kickstarter {
      color: #061b57;
      border-color: #061b57;

      .icon {
        background: data-uri('public/icons/support/kickstarter.svg') no-repeat
          center;
      }
    }

    &.gofundme {
      color: #00b460;

      .icon {
        background: data-uri('public/icons/support/gofundme.svg') no-repeat
          center;
      }
    }

    &.other {
      color: var(--biositeColorProfile-buttonText);
    }
  }

  .button_filler {
    width: 100%;
    height: 10px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 32px;

    .icon {
      width: 16px;
      height: 16px;
      color: #000;
    }
  }

  .price {
    flex-shrink: 0;
    padding-left: 0;
    word-break: normal;
    opacity: 0;
  }
}

.error {
  display: none;
  margin-top: 20px;
  line-height: 24px;
  text-align: center;

  &.show {
    display: block;
  }

  .message {
    display: inline-block;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 500;
    color: var(--biositeColorProfile-buttonText);
    background: data-uri('public/icons/common/error.svg') no-repeat left;
  }
}
