@import 'BioSite/styles/sizes.less';

@title-color: #010101;
@subtitle-color: #9c9c9c;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem 1.65rem;

  button {
    font-family: 'Clarkson', sans-serif;
    color: #ffffff;
    text-transform: uppercase;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .title {
      font-family: 'Clarkson', sans-serif;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.16rem;
      color: @title-color;
    }

    .subtitle {
      font-family: 'Clarkson', sans-serif;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: @subtitle-color;
    }
  }
}
