.textbox {
  position: relative;
  padding: 30px 22px;

  .title {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    font-size: 15px;
    font-weight: normal;
    line-height: 23px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
