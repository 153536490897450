@keyframes donutCircleKeyframes {
  0% {
    opacity: 1;
    transform: rotateY(0deg);
  }

  3% {
    opacity: 1;
    transform: rotateY(-50deg);
  }

  5% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  5.1% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  69.9% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  70% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  72% {
    opacity: 1;
    transform: rotateY(-50deg);
  }

  75% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes blackCircleKeyframes {
  4.9% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  5% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  20% {
    opacity: 1;
    transform: rotateY(-450deg);
  }

  20.1% {
    opacity: 0;
    transform: rotateY(-450deg);
  }

  54.9% {
    opacity: 0;
    transform: rotateY(-450deg);
  }

  55% {
    opacity: 1;
    transform: rotateY(-450deg);
  }

  70% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  70.1% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes profilePictureKeyframes {
  19.9% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  20% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  22% {
    opacity: 1;
    transform: rotateY(-50deg);
  }

  25% {
    opacity: 1;
    transform: rotateY(0deg);
  }

  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }

  53% {
    opacity: 1;
    transform: rotateY(-50deg);
  }

  55% {
    opacity: 1;
    transform: rotateY(-90deg);
  }

  55.1% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

.container {
  position: relative;
  width: 24px;
  height: 18px;

  svg {
    display: block;
  }

  .donutCircle {
    position: absolute;
    transform-origin: 9px center;
    animation: donutCircleKeyframes 10s infinite linear;
  }

  .blackCircle {
    position: absolute;
    opacity: 0;
    transform-origin: 9px center;
    animation: blackCircleKeyframes 10s infinite linear;
  }

  .profilePic {
    position: absolute;
    width: 17px;
    height: 17px;
    opacity: 0;
    animation: profilePictureKeyframes 10s infinite linear;

    .avatar {
      position: relative;
      flex-shrink: 0;
      width: 17px;
      height: 17px;
      margin-top: 1px;

      margin-left: 0;
      font-family: 'Clarkson', sans-serif;
      color: #d9d9d9;
      cursor: pointer;
      user-select: none;
      transition: margin-left 0.4s;

      img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        object-fit: cover;
        border-radius: 100%;
      }

      .bigCircle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        background: #000;
        border-radius: 100%;
      }

      .smallCircle {
        position: absolute;
        right: -3px;
        bottom: 0;
        width: 3.5px;
        height: 3.5px;
        border-radius: 100%;
        opacity: 1;
        transition: opacity 0.2s;
        transition:
          width 0.4s,
          height 0.4s,
          right 0.4s;
      }
    }
  }
}
