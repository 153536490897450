.isHover {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background: var(--biositeColorProfile-backgroundHover);
  }
}
