@import 'BioSite/styles/vars';
@border-width: 0.14rem;
@background-color: #fff;
@color: #1b1b1b;

.availableTime {
  width: 100%;
  padding: 1rem 0;
  font-family: 'Clarkson', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  color: @color;
  background-color: @background-color;
  border-radius: 0.6rem;
  box-shadow: @buttonSecondaryShadow;

  &.selected {
    padding: calc(1rem - @border-width) 0;
    border: @border-width solid #010101;
  }
}
