.content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  padding: 2.4rem;
  background: var(--biositeColorProfile-buttonBackgroundSolid);
}

.title {
  margin: 0;
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 700;
  line-height: 2.16rem;
  color: var(--biositeColorProfile-buttonText);
  text-align: center;
}

.description {
  margin: 0;
  font-family: var(--secondary-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--biositeColorProfile-buttonTextDim);
  text-align: center;
}

.button {
  height: 6rem;
  font-size: 16px;
  font-weight: 500;
  color: var(--biositeColorProfile-buttonTextText);
  text-align: center;
  cursor: pointer;
  background-color: var(--biositeColorProfile-buttonText);
  border: 2px solid var(--biositeColorProfile-buttonText);
  border-radius: 2.5px;
}
