@import '../../../styles/modals.less';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  padding: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: @overlay-background;
  backdrop-filter: @overlay-backdrop-filter;
}

.modal {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 485px;
  max-height: 100%;
  overflow-y: auto;
  background: var(--biositeColorProfile-buttonBackgroundSolid);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 100%;

  svg {
    width: 14px;
    height: 14px;
  }
}
