.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .fallbackImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    object-fit: cover;
  }
}

.mobileVideo {
  video::-webkit-media-controls {
    display: none !important;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
}
