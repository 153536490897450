.nft_popover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -5;
  width: 100vw;
  width: 100%;
  height: 100vh;
  background: var(--biositeColorProfile-buttonBackgroundSolid);
  opacity: 0;
  transition:
    opacity 0.6s,
    z-index 0.6s;

  &.show {
    z-index: 10001;
    display: block;
    opacity: 1;
  }

  .top_bar {
    position: fixed;
    z-index: 200;
    display: inline-block;
    width: 100%;
    max-width: 485px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    background: var(--biositeColorProfile-buttonBackgroundSolid);
    border-bottom: 1px solid var(--biositeColorProfile-buttonTextDim);

    .title {
      font-size: 13px;
      color: var(--biositeColorProfile-buttonText);
      text-align: center;
    }

    .close {
      position: absolute;
      top: 2px;
      right: 10px;
      width: 33px;
      height: 33px;
      cursor: pointer;
      background: data-uri('public/icons/common/close.svg') no-repeat center;
    }
  }

  .foreground {
    max-width: 485px;
    height: 100%;
    margin: 0 auto;
    margin-top: 20px;
    overflow-y: auto;
    text-align: left;
    background: var(--biositeColorProfile-background);
    transition: all 0.6s;

    &.show {
      margin-top: 0;
    }
  }

  ul {
    padding: 37px 0 0 0;
    margin: 0;
    list-style: none;

    li {
      img {
        display: block;
        width: 100%;
      }

      video {
        width: 100%;
        height: 445px;
        object-fit: cover;
      }

      .content {
        padding: 20px;
        color: var(--biositeColorProfile-buttonText);

        a {
          color: var(--biositeColorProfile-buttonText);
        }

        .title {
          padding-top: 19px;
          padding-bottom: 13px;
          font-size: 24px;
        }

        .description {
          padding-bottom: 10px;
          font-size: 12px;
          line-height: 16.5px;
          color: var(--biositeColorProfile-buttonTextDim);
        }

        .links {
          display: flex;
          justify-content: space-between;
          max-height: 0px;
          padding-top: 0;
          padding-bottom: 0;
          overflow: hidden;
          font-size: 13px;
          line-height: 13px;
          text-transform: uppercase;
          border-bottom: 0;
          transition: all 0.5s ease-in-out;

          a {
            cursor: pointer;
          }

          &.show {
            max-height: 75px;
            padding-top: 20px;
            padding-bottom: 25px;
            border-bottom: 1px solid var(--biositeColorProfile-buttonTextDim);
          }
        }

        .more {
          max-height: 0;
          margin-top: 0;
          overflow: hidden;
          transition: all 1s ease-in-out;

          .profile {
            display: flex;
            justify-content: start;
            padding: 10px 0;

            .thumbnail {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 38px;
              height: 38px;
              overflow: hidden;
              background: var(--biositeColorProfile-buttonTextDim);
              border: none;
              border-radius: 50%;

              img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
              }
            }

            .details {
              padding: 0 16px;
              margin-top: -3px;
              text-transform: uppercase;

              .label {
                font-size: 11px;
                line-height: 11px;
                color: var(--biositeColorProfile-buttonTextDim);
              }

              .name {
                font-size: 14px;
                line-height: 13px;

                a {
                  text-decoration: underline;
                }
              }
            }
          }

          .row {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            text-transform: uppercase;

            &.year {
              margin-top: 10px;
            }

            .label {
              font-size: 13px;
              color: var(--biositeColorProfile-buttonTextDim);
            }

            .value {
              font-size: 13px;
            }
          }

          .more_links {
            a {
              display: block;
              margin: 15px 0;
              font-size: 14px;
              text-align: center;
              text-transform: uppercase;
              cursor: pointer;
            }

            .opensea {
              padding: 15px;
              color: var(--biositeColorProfile-buttonTextText);
              background: var(--biositeColorProfile-buttonText);
              border-radius: 8px;
            }
          }

          &.show {
            max-height: 430px;
            border-bottom: 1px solid var(--biositeColorProfile-buttonTextDim);
          }
        }
      }
    }
  }
}
