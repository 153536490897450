@import 'BioSite/styles/template/common/colors';
@import 'BioSite/styles/vars';

.embed_video {
  &.youtube_shorts {
    .video {
      padding-top: 177.78% !important; //padding is based on width. This gives us a 9:16 aspect ratio
      overflow: hidden;
      border-radius: @sectionBorderRadius;
    }
  }

  .video {
    position: relative;
    padding-top: 56.25% !important; //padding is based on width. This gives us a 16:9 aspect ratio

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      border: none;
    }
  }
}

.embed_preview {
  padding: 1.5rem;
  text-align: left;
  background: #fff;
  border: 0.1rem dashed #979797;
  border-radius: @sectionBorderRadius;
  box-shadow: @sectionShadow;

  .title {
    display: flex;
    font-size: 1.4rem;
    line-height: 3.6rem;
    color: #4d4d4d;
  }

  .description {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.4rem;
    color: #bdbdbd;
  }

  & > .youtube {
    border-color: @YOUTUBE_COLOR;

    .title {
      color: @YOUTUBE_COLOR;
    }
  }

  & > .vimeo {
    border-color: @VIMEO_COLOR;

    .title {
      color: @VIMEO_COLOR;
    }
  }

  & > .twitch {
    border-color: @TWITCH_COLOR;

    .title {
      color: @TWITCH_COLOR;
    }
  }
}
