@import 'BioSite/styles/vars';
@import 'BioSite/styles/template/common/colors.less';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  font-family: 'Clarkson', sans-serif;
  color: black;

  .title {
    margin-bottom: 0;
    font-size: 2.1rem;
    font-weight: 500;
  }

  .description {
    font-size: 1.4rem;
    color: @greyText;
  }

  .title,
  .description {
    text-align: center;
  }
}

.card {
  width: 100%;
  max-width: 80rem;
  margin-top: 1.4rem;
  font-family: 'Clarkson', sans-serif;

  & > div {
    font-family: 'Clarkson', sans-serif;
  }

  .buttons {
    width: 100%;

    :global .other-buttons {
      margin-top: 1.4rem;
      font-family: 'Clarkson', sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 6rem;
      color: var(--biositeColorProfile-buttonText);
      text-transform: uppercase;
      background-color: var(--biositeColorProfile-buttonBackground);
      box-shadow: @buttonShadow;
    }
  }
}
