@import 'BioSite/styles/vars';

@contentLateralPadding: 2rem;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
}

.content {
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  padding: 4rem @contentLateralPadding;
  overflow-y: auto;
  font-family: 'Clarkson', sans-serif;
}

.footer {
  box-sizing: border-box;
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 100vw;
  padding: 2rem;
  font-family: 'Clarkson', sans-serif;
  background-color: @full-page-modal-background;
  box-shadow: @full-page-modal-footer-box-shadow;

  button {
    max-width: calc(@sectionMaxWidth - calc(@contentLateralPadding * 2));
    font-family: 'Clarkson', sans-serif;
  }
}
