.slideInMixin {
  @keyframes slidein {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }

    23.5% {
      opacity: 1;
      transform: translateX(0);
    }

    85% {
      opacity: 1;
      transform: translateX(0);
    }

    100% {
      opacity: 1;
      transform: translateX(35px);
    }
  }
}
