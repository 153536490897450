@import (reference) '../Buttons/Buttons.less';
@import (reference) '../Cards/Cards.less';
@import (reference) '../Advanced/Advanced01/Advanced01.less';
@import (reference) '../Advanced/Advanced02/Advanced02.less';
@import (reference) '../Advanced/Advanced03/Advanced03.less';
@import (reference) '../Advanced/Advanced04/Advanced04.less';
@import (reference) '../Advanced/Advanced05/Advanced05.less';
@import (reference) '../Advanced/Advanced06/Advanced06.less';
@import (reference) '../Advanced/Advanced07/Advanced07.less';
@import (reference) '../Advanced/Advanced08/Advanced08.less';

.placeholder-common() {
  background-color: #e8ebea;
  border: 1px dashed #979797 !important;
}

//
// Buttons
//

.placeholder-buttons-01 {
  .buttons-01();
  .placeholder-common();
}

.placeholder-buttons-02 {
  .buttons-02();
  .placeholder-common();
}

.placeholder-buttons-03 {
  .buttons-03();
  .placeholder-common();
}

.placeholder-buttons-04 {
  .buttons-04();
  .placeholder-common();
  border-radius: 64px;
}

.placeholder-buttons-05 {
  .buttons-05();
  .placeholder-common();
  position: relative;
  margin-bottom: 16px;

  &::after {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--biositeColorProfile-textDim);
  }
}

.placeholder-buttons-06 {
  .buttons-06();
  .placeholder-common();
  position: relative;
  margin-bottom: 16px;

  &::after {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: var(--biositeColorProfile-textDim);
  }
}

.placeholder-buttons-07 {
  .buttons-07();
  .placeholder-common();
  position: relative;
  margin-right: -19px;
  margin-bottom: 12px;
  margin-left: -19px;

  &:first-child::before,
  &::after {
    position: absolute;
    bottom: -7px;
    left: -6px;
    width: calc(100% + 12px);
    height: 1px;
    content: '';
    background: var(--biositeColorProfile-textDim);
  }

  &:first-child {
    &::before {
      top: -7px;
      bottom: auto;
    }
  }
}

.placeholder-buttons-08 {
  .buttons-08();
  .placeholder-common();
  position: relative;
  min-height: 52px;
  margin: 0;
  margin-bottom: 22px;

  &::before {
    position: absolute;
    top: -12px;
  }

  &::after {
    position: absolute;
    bottom: -12px;
  }
}

//
// Cards
//

.placeholder-cards-01 {
  .cards-01();
  .placeholder-common();
}

.placeholder-cards-02 {
  .cards-02();
  .placeholder-common();
}

.placeholder-cards-03 {
  .cards-03();
  .placeholder-common();
}

.placeholder-cards-04 {
  .cards-04();
  .placeholder-common();
}

.placeholder-cards-05 {
  .cards-05();
  .placeholder-common();
}

.placeholder-cards-06 {
  .cards-06();
  .placeholder-common();
}

.placeholder-cards-07 {
  .cards-07();
  .placeholder-common();
}

:global(.showPlaceholders.body_section.links.cards-08) {
  grid-auto-rows: 64px;
}

.placeholder-cards-08 {
  .cards-08();
  .placeholder-common();
  height: 100%;

  &:first-child {
    grid-row-end: span 2;
  }

  &:nth-child(3) {
    grid-row-start: 3;
  }

  &:last-child {
    grid-row-end: span 2;
    grid-column-start: 2;
  }
}

//
// Advanced
//

.placeholder-advanced-01 {
  .advanced-01();
  .placeholder-common();
  opacity: 1;
  transform: none;
}

.placeholder-advanced-02 {
  .advanced-02();
  .placeholder-common();
  opacity: 1;
  transform: none;
}

.placeholder-advanced-03 {
  .advanced-03();
  .placeholder-common();
  opacity: 1;
  transform: none;
}

.placeholder-advanced-04 {
  .advanced-04();
  .placeholder-common();
  opacity: 1;
  transform: none !important;
}

.placeholder-advanced-05 {
  .advanced-05();
  .placeholder-common();
  z-index: 15;
  background-color: #e8e8e8; // equivalent to `placeholder-common` but without transparency

  &:nth-child(2) {
    margin-top: -68px;
    transform: scaleX(0.9);
  }

  &:nth-child(3) {
    margin-top: -68px;
    transform: scaleX(0.8);
  }

  &:last-child {
    display: none;
  }
}

:global(.showPlaceholders.body_section.links.advanced-06) {
  grid-auto-rows: 90px;
}

.placeholder-advanced-06 {
  .advanced-06();
  .placeholder-common();
  height: 100%;
  opacity: 1;
  transform: translateY(0);

  &:first-child {
    grid-row-end: span 2;
  }

  &:nth-child(3) {
    grid-row-start: 3;
  }

  &:last-child {
    grid-row-end: span 2;
    grid-column-start: 2;
  }
}

.placeholder-advanced-07 {
  .advanced-07();
  .placeholder-common();
  min-height: auto;
  aspect-ratio: 1;
  border-radius: 8px;
  opacity: 1;
  transform: scale(1);
}

.placeholder-advanced-08 {
  .advanced-08();
  .placeholder-common();
  opacity: 1;
  transform: scale(1);
}
