@import 'BioSite/styles/template/common/colors.less';
@import 'BioSite/styles/template/common/common.less';

.placeholder {
  width: 35px;
  height: 35px;
  margin: 0 3px;
  background: #e8ebea;
  filter: none;
  border: 1px dashed #979797;
  border-radius: 50%;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--biositeColorProfile-text);

  @keyframes animate-social {
    0% {
      transform: scale(0);
    }

    95% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  animation: animate-social 0.3s backwards;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: none;
    }
  }

  &.amazon,
  &.applemusic {
    position: relative;
    top: 2px;
  }

  &.amazon,
  &.clubhouse {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.discord,
  &.email,
  &.soundcloud {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.applemusic {
    top: 1px;
  }
}

.socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: @sectionVerticalPadding 0;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
}
