.button-reset {
  width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  font: inherit;
  cursor: pointer;
  background: transparent;
  border: none;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    overflow-wrap: anywhere;
  }
}

.intro-animation {
  @keyframes animate-link {
    0% {
      top: 60px;
      transform: scale(0) translateZ(0);
    }

    100% {
      top: 0;
      transform: scale(1) translateZ(0);
    }
  }

  --animation-timing: calc(var(--animation-delay) / 5);
  animation: animate-link calc(var(--animation-timing) + 0.4s) backwards;
}
