@import 'BioSite/styles/sizes.less';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  padding: 2.6rem 0;

  .title {
    width: 100%;
    font-family: 'Clarkson', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #9c9c9c;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    button {
      font-family: 'Clarkson', sans-serif;
      color: #0e0e0e;
      text-transform: uppercase;
      background: #ffffff;
    }
  }
}
