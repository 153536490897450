@import 'BioSite/styles/template/common/mixins';
@import 'BioSite/styles/vars';

.container {
  overflow: hidden;
  border-radius: @sectionBorderRadius;
  box-shadow: @sectionShadow;
}

.mailing_list {
  position: relative;

  .sectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: @sectionImageMaxHeight;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
    }
  }

  .content {
    padding: @sectionContentPadding;
    background-color: var(--biositeColorProfile-buttonBackground);

    &.has_image {
      border-top: none !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .description {
      margin-top: 8px;
      margin-bottom: 24px;
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      color: var(--biositeColorProfile-buttonTextDim);
    }

    .title {
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: var(--biositeColorProfile-buttonText);
    }

    .submitSuccess {
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: var(--biositeColorProfile-buttonText);
    }

    .input {
      margin-bottom: 10px;

      &.hasError {
        margin-bottom: 32px;
      }
    }
  }

  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: var(--biositeColorProfile-buttonTextText);
    text-transform: capitalize;
    cursor: pointer;
    background: var(--biositeColorProfile-buttonText);
    border-radius: 6px;

    &[disabled] {
      color: var(--biositeColorProfile-buttonTextText);
      cursor: default;
      background: var(--biositeColorProfile-buttonTextDim);
    }

    &.placeholder {
      color: #6a6a6a;
      background: #e8ebea;
      border: 1px dashed #979797;
    }
  }

  .submitError {
    display: flex;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #ff7c7c;
  }
}
