@import 'BioSite/styles/modals.less';
@import 'BioSite/styles/vars.less';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100dvh;
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: @modalBackgroundColor;

  .content {
    overflow-y: auto;
  }
}
