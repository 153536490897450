.variant4_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;

  .textContainer {
    @keyframes moveUpAndDown {
      0% {
        transform: translateY(0);
      }

      45% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-100%);
      }

      95% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    height: 10px;
    overflow: hidden;
    color: var(--biositeColorProfile-text);

    span {
      display: block;
      animation: moveUpAndDown 9s ease-out infinite;
    }
  }
}
