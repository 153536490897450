@import 'BioSite/styles/vars';
@import 'BioSite/styles/template/common/colors.less';

.inputContainer {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 4rem;
  margin-top: 2.4rem;
  border: 1px solid var(--biositeColorProfile-buttonTextDim);
  border-radius: 0.6rem;
  outline: none;

  .iconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    width: 1.6rem;
    height: 2.1rem;
    padding-left: 1.2rem;
    color: var(--biositeColorProfile-buttonTextDim);
  }

  &:focus-within,
  &.hasInput {
    color: var(--biositeColorProfile-buttonText);
    border-color: var(--biositeColorProfile-buttonText);

    &:not(:focus-within) {
      border: 0.1rem solid var(--biositeColorProfile-buttonTextDim);
    }
  }
}

.textInput {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 4rem;
  font-size: 1.2rem;
  color: var(--biositeColorProfile-buttonText);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: unset;
  border: none;
  border-radius: inherit;
  outline: none;
  box-shadow: none;

  &::placeholder {
    -webkit-text-fill-color: var(--biositeColorProfile-buttonTextDim);
    color: var(--biositeColorProfile-buttonTextDim);
  }
}

.error {
  display: flex;
  margin-top: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: @errorRed;
}
