.monetization_error {
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  margin-top: 16px;
  text-align: left;
  background: #fff;
  border: 1px solid #e8ebea;
  border-radius: 2.5px;

  .monetization_error_title {
    display: flex;
    padding-left: 32px;
    font-size: 16px;
    font-weight: 700;
    color: #ed8e3a;
    background: data-uri('public/icons/common/warning_triangle.svg') no-repeat
      top left;
  }

  .monetization_error_description {
    padding-left: 32px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #bdbdbd;
  }
}
