@import (reference) '../mixins.less';

:global(.body_section.links.advanced-07) {
  filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.06));
  @media (max-width: 990px) {
    padding: 10px 0;
  }
}

// Repeat the previews rules because in preview the media query is not true

:global(.body_section.links.advanced-07.isPreviewMode) {
  padding: 10px 0;
}

.advanced-07 {
  .advanced-link(none);
  width: 110px;
  min-height: 136px;
  color: var(--biositeColorProfile-text) !important;
  background-color: transparent;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform: scale(0.8);

  &:not(.isLast) {
    margin-right: 8px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  &.isVisible {
    opacity: 1;
    transform: scale(1);
  }

  .thumbnailWrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    margin-bottom: 8px;
    overflow: hidden;
    background-color: var(--biositeColorProfile-buttonBackground);
    border-radius: 8px;

    .thumbnailPlaceholder {
      font-size: 32px;
      line-height: 1.75;
      color: var(--biositeColorProfile-buttonTextDim);
    }

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    box-sizing: border-box;
    padding: 0 8px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }

  .name {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    &.singleLine {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.multiLine {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
