@import 'BioSite/styles/template/common/common';

.linked_social_grid {
  padding: @sectionVerticalPadding 0;

  .grid_container {
    overflow: hidden;
    transition: height 0.5s;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2px;
    column-gap: 2px;
    padding: 0 2px;

    &.one_column {
      grid-template-columns: 1fr;
    }

    &.two_column {
      grid-template-columns: 1fr 1fr;
    }
  }

  .buttons {
    display: flex;
    justify-content: stretch;
    border-bottom: 1px solid var(--biositeColorProfile-textDim);

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 22px 28px;
      font-family: 'Clarkson', sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      color: var(--biositeColorProfile-text);
      text-transform: uppercase;
      letter-spacing: 0.4px;
      cursor: pointer;
      background: transparent;
      border: none;

      &:only-child {
        width: 100%;
      }

      .icon {
        width: 7px;
        height: 7px;
        margin-left: 5px;
        transform: rotate(90deg);

        svg {
          display: block;
        }
      }

      &.back {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
