@import 'BioSite/styles/sizes';
@import 'BioSite/styles/vars';
@import 'BioSite/styles/template/common/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Clarkson', sans-serif;

  & > section {
    max-width: none;
    padding: 4.6rem 0;
    margin: 0;
  }
}

.calendar {
  margin-top: 4.6rem;
}

.loading,
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error {
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: @errorRed;
  text-align: center;
}
