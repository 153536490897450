@import (reference) '../mixins.less';

@defaultShadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);

.advanced-link(@shadow: @defaultShadow) {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin: auto;
  overflow: hidden;
  font-weight: inherit;
  color: var(--biositeColorProfile-buttonText);
  text-align: start;
  cursor: pointer;
  background-color: var(--biositeColorProfile-buttonBackground);
  border: none;
  box-shadow: @shadow;
}

.thumbnail-overlay() {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      var(--biositeColorProfile-linkThumbnailBackground) 100%
    );
  }
}
