@import (reference) '../mixins.less';

:global(.body_section.links.buttons-01),
:global(.body_section.links.buttons-02),
:global(.body_section.links.buttons-03),
:global(.body_section.links.buttons-04) {
  filter: drop-shadow(0 6px 40px rgba(0, 0, 0, 0.06));
}

.buttons-01 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  min-height: 64px;
  padding: 4px;
  padding-right: 22px;
  margin-bottom: 16px;
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 2px;

  &.hasThumbnail {
    text-align: left;
  }

  &:not(.hasThumbnail) {
    padding-left: 22px;
  }

  &.hasPrice {
    .text {
      display: flex;
      gap: 22px;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 2px;
  }

  .textContainer {
    flex: 1;
    align-self: center;
    font-size: 14px;
    line-height: 1.28;
    color: var(--biositeColorProfile-buttonText);
  }
}

.buttons-02 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  min-height: 80px;
  padding: 12px;
  padding-right: 22px;
  margin-bottom: 16px;
  font-size: 16px;
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 43px;

  &.hasThumbnail {
    text-align: left;
  }

  &:not(.hasThumbnail) {
    padding-left: 22px;
  }

  &.hasPrice {
    .text {
      display: flex;
      gap: 22px;
      align-items: center;
      justify-content: space-between;
    }
  }

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 100%;
  }

  .textContainer {
    flex: 1;
    align-self: center;
    line-height: 1.25;
    color: var(--biositeColorProfile-buttonText);
  }
}

.buttons-03 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 96px;
  margin-bottom: 12px;
  background: var(--biositeColorProfile-buttonBackground);
  border-radius: 12px;

  &.hasPrice {
    .textContainer {
      width: 100%;
    }

    .text {
      display: flex;
      gap: 22px;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }

  &:global(.canAnimate) {
    .intro-animation();
  }

  .textContainer {
    margin: 10px 22px;
    font-size: 16px;
    line-height: 1.125;
    color: var(--biositeColorProfile-buttonText);
    text-align: left;
  }

  .thumbnail {
    display: block;
    flex-shrink: 0;
    align-self: flex-start;
    width: 92px;
    height: 92px;
    margin: 2px 0;
    margin-left: 2px;
    object-fit: cover;
    border-radius: 12px;
  }
}

.buttons-04 {
  .button-reset();
  display: flex;
  align-items: start;
  min-height: 88px;
  margin-bottom: 10px;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &.hasPrice {
    .text {
      display: flex;
      gap: 22px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    flex-shrink: 0;
    width: 88px;
    height: 88px;
    object-fit: cover;
    border-radius: 50%;
  }

  .textContainer {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-height: 88px;
    padding: 28px 22px;
    font-size: 16px;
    line-height: 1.22;
    color: var(--biositeColorProfile-buttonText);
    text-align: left;
    background: var(--biositeColorProfile-buttonBackground);
    border-radius: 64px;
  }
}

.buttons-05 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 68px;
  padding: 11px 0;
  background: transparent;
  border-bottom: 1px solid var(--biositeColorProfile-textDim);

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    flex-shrink: 0;
    align-self: flex-start;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 50px;
  }

  .textContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    line-height: 1.23;
    color: var(--biositeColorProfile-text);
    text-align: start;
    text-transform: uppercase;

    .rightSide {
      padding-left: 22px;
    }
  }
}

.buttons-06 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 66px;
  padding: 6px 0;
  background: transparent;
  border-bottom: 1px solid var(--biositeColorProfile-textDim);

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    flex-shrink: 0;
    align-self: flex-start;
    width: 52px;
    height: 52px;
    margin-right: 12px;
    object-fit: cover;
  }

  .textContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.25;
    color: var(--biositeColorProfile-text);
    text-align: left;

    .rightSide {
      display: flex;
      align-items: center;
      padding-left: 22px;
    }
  }
}

.buttons-07 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 14px 22px;
  margin: 0 -22px;
  // `!important` because it needs to override potential template animations
  border-top: 1px solid var(--biositeColorProfile-text) !important;

  &:last-child {
    border-bottom: 1px solid var(--biositeColorProfile-text);
  }

  &.hasThumbnail {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.hasPrice {
    .textContainer {
      width: 100%;
    }

    .text {
      display: flex;
      gap: 22px;
      justify-content: space-between;
    }
  }

  &:global(.canAnimate) {
    .intro-animation();
  }

  .thumbnail {
    flex-shrink: 0;
    align-self: flex-start;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    object-fit: cover;
    border: 1px solid var(--biositeColorProfile-buttonText);
    border-radius: 50%;
  }

  .textContainer {
    font-size: 26px;
    line-height: 1.15;
    color: var(--biositeColorProfile-text);
    text-align: left;
  }
}

.buttons-08 {
  .button-reset();
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 22px;
  margin: 0 -22px;

  &:global(.canAnimate) {
    .intro-animation();
  }

  .line {
    width: 100vw;
    content: '';
    border-bottom: 1px solid var(--biositeColorProfile-textDim);

    // Check if device is touch screen
    @media (hover: hover) {
      width: calc(100vw - 15px);
    }
  }

  &::before {
    .line();
    margin: 0;
  }

  &:last-child {
    &::after {
      .line();
      margin-bottom: 0;
    }
  }

  .thumbnail {
    width: 100%;
    height: auto;
    aspect-ratio: 486 / 363;
    margin-top: 22px;
    object-fit: cover;
  }

  .textContainer {
    display: flex;
    flex-grow: 1;
    gap: 22px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 27px 0;
    color: var(--biositeColorProfile-text);

    .text {
      font-size: 20px;
      line-height: 1.16;
      text-align: left;
    }

    .rightSide {
      align-self: flex-end;
      padding-bottom: 4px;
      font-size: 12px;
    }
  }
}
