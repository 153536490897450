@import '../../styles/template/common/mixins.less';

.content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 220px;
  height: 40px;
  margin: 0 25px;
  overflow: hidden;
  font-size: 10px;
  color: var(--biositeColorProfile-text);
  letter-spacing: 0.2px;
  border: 1px solid var(--biositeColorProfile-textDim);
  border-radius: 48px;

  &.variant2 {
    width: 100%;
    max-width: 485px;
    height: 60px;
  }

  &.variant3,
  &.variant7 {
    justify-content: center;
    height: 54px;
    border: none;
    border-radius: 0;
  }

  &.variant4 {
    height: unset;
    border: none;
  }

  &.variant5 {
    width: 100%;
    max-width: 485px;
    height: 60px;
  }

  &.variant6 {
    width: 240px;
    height: 54px;
  }

  &.variant8 {
    width: 300px;
    height: 54px;
    color: #fff;
    background: var(--biositeColorProfile-watermarkBackground);
    border: none;
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    font-family: 'Clarkson', sans-serif;
    font-weight: normal;
    color: inherit;
    text-align: left;

    svg {
      display: block;
      flex-shrink: 0;

      &:first-child {
        width: 20px;
        height: 17px;
      }
    }

    &.variant2 {
      gap: 110px;
      padding-left: 20px;
    }

    &.variant8 {
      padding: 0 20px;

      svg {
        color: #fff;
      }
    }

    .button_text {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      width: max-content;

      svg {
        margin-top: -1px;
        margin-bottom: 1px;
      }
    }

    .arrow {
      position: relative;
      right: 10px;

      &.default {
        right: 5px;
      }

      &.variant1 {
        width: 10px;

        .slideInMixin();
        animation: slidein 3s infinite 0.5s;
      }

      &.variant8 {
        right: 0;
      }
    }
  }
}
