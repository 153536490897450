@import (reference) './Layouts/mixins.less';

:global(.header) {
  h1 {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
  }
}

//
// Placeholder
//

.placeholder {
  .placeholderContent();
}

//
// Minimal
//

.minimal-01 {
  .flexColumnCenter();
  padding-bottom: 10px;
  text-align: center;

  &.hasCoverPhoto,
  &.hasPlaceholder {
    .profileWrapper {
      margin-top: -90px; // 160px from the cover height - 78px of the above space we should leave

      @media (min-width: 640px) {
        margin-top: -66px;
      }
    }
  }

  &.inPreviewMode.hasCoverPhoto {
    .profileWrapper {
      margin-top: -90px;
    }
  }

  img {
    .fitImage();
  }

  &.hasPlaceholder {
    .coverWrapper {
      clip-path: url(#minimal-01-mask);
    }
  }

  .coverWrapper {
    .blurredCoverWrapper();
    z-index: -1;
    height: 174px;
    clip-path: url(#minimal-01-mask);
    .minimalGenericCoverEntrance();

    @media (max-width: 639px) {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
        box-shadow: 0 -30px 50px -10px rgba(128, 128, 128, 0.29) inset;
      }
    }
  }

  &:not(.inPreviewMode) {
    .coverWrapper {
      @media (min-width: 640px) {
        clip-path: none;
      }

      @media (min-width: 640px) {
        height: 174px;
      }
    }
  }

  .profileWrapper {
    z-index: 5;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    width: 132px;
    height: 132px;
    padding: 5px;
    margin-top: 28px;
    background: linear-gradient(
      180deg,
      var(--biositeColorProfile-backgroundSolidHeader) 0%,
      var(--biositeColorProfile-backgroundSolidHeader) 53%,
      transparent 53%
    );
    border-radius: 50%;

    &.noCoverPhoto {
      background: none;
    }

    .profile {
      box-sizing: border-box;
      width: 112px;
      height: 112px;
      border: 6px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.12);
      .minimalGenericProfileEntrance();

      &.placeholder {
        .placeholderContent();
      }
    }
  }

  .name,
  .bio {
    .containerGutter();
  }

  .name {
    margin: 8px 0 0;
    font-size: 28px;
    font-weight: var(--primary-font-weight);
    line-height: 1.2;
  }

  .bio {
    margin: 6px 0 0;
    font-size: 11px;
    line-height: 1.64;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

.minimal-02 {
  .flexColumnCenter();
  position: relative;
  padding-bottom: 20px;
  text-transform: uppercase;

  img {
    .fitImage();
  }

  .name,
  .bio {
    box-sizing: border-box;
    max-width: 600px;
    margin: 0;
    overflow-wrap: break-word;
  }

  &.hasBio {
    .name {
      margin-top: 20px;
    }
  }

  .bio {
    z-index: 5;
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  .name {
    z-index: 5;
    max-width: 340px;
    min-height: 110px;
    margin-top: 24px;
    margin-bottom: 0;
    font-size: 60px;
    font-weight: var(--primary-font-weight, 400);
    line-height: 1;
  }

  &.hasCoverPhoto {
    min-height: 257px;
  }

  .coverWrapper {
    .blurredCoverWrapper();
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 280px;
    .minimalGenericCoverEntrance();

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      content: '';
      background: var(--biositeColorProfile-textOverlay);
    }
  }

  .profile {
    z-index: 4;
    width: 232px;
    height: 232px;
    margin-top: -25px;
    border-radius: 50%;
    .minimalGenericProfileEntrance();
  }
}

.minimal-03 {
  @keyframes singleImageEntrance {
    from {
      transform: translateX(-10%) rotate(-20deg);
    }

    to {
      transform: translateX(0) rotate(-12deg);
    }
  }

  @keyframes minimal03ProfileEntrance {
    from {
      transform: translateX(calc(-60% - 52px)) rotate(-20deg);
    }

    to {
      transform: translateX(calc(-50% - 52px)) rotate(-12deg);
    }
  }

  @keyframes minimal03CoverEntrance {
    from {
      transform: translateX(calc(-40% + 52px)) rotate(24deg);
    }

    to {
      transform: translateX(calc(-50% + 52px)) rotate(16deg);
    }
  }

  .flexColumnCenter();
  padding-bottom: 10px;
  text-align: center;

  .placeholder {
    &.cover {
      background-color: #e8e8e8;
    }
  }

  .name,
  .coverWrapper,
  .bio {
    .containerGutter();
  }

  .name {
    display: flex;
    align-items: center;
    min-height: 80px;
    padding-top: 28px;
    margin: 0;
    font-size: 28px;
    line-height: 1.2;
  }

  .imagesWrapper {
    position: relative;
    width: 100%;
    height: 172px;
    margin: 32px 0;
    filter: drop-shadow(0 20px 80px rgba(0, 0, 0, 0.6));

    .profile,
    .cover {
      position: absolute;
      left: 50%;
      margin: 0;
    }

    .profile {
      top: 12px;
      z-index: 2;
      transform: translateX(calc(-50% - 52px)) rotate(-12deg) translateZ(0);
      animation: minimal03ProfileEntrance 0.4s ease-out;
    }

    .cover {
      bottom: 15px;
      transform: translateX(calc(-50% + 52px)) rotate(16deg);
      animation: minimal03CoverEntrance 0.4s ease-out;
    }
  }

  .profile,
  .cover {
    width: 124px;
    height: 124px;
    margin: 32px 0;
    object-fit: cover;
    transform: rotate(-12deg);
  }

  &:not(.hasPlaceholder) {
    .profile,
    .cover {
      &:not(.hasBothImages) {
        animation: singleImageEntrance 0.4s ease-out;
      }
    }
  }

  .bio {
    margin: 8px 0 12px;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    opacity: 0.7;
  }
}

.minimal-04 {
  --profile-image-size: 80px;
  padding-bottom: 10px;
  overflow: visible;

  .bodyWrapper {
    .containerGutter();
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: auto;
    margin-left: auto;

    &::after {
      align-self: center;
      order: 4;
      width: 100vw;
      height: 1px;
      margin-top: 29px;
      margin-bottom: 22px;
      content: '';
      border-top: 1px solid var(--biositeColorProfile-textDim);
    }
  }

  img {
    .fitImage();
  }

  &.hasCoverPhoto,
  &.hasPlaceholder {
    .profile {
      margin-top: calc(-0.5 * (var(--profile-image-size)));
    }
  }

  &:not(.hasProfilePhoto) {
    .name {
      margin-top: 16px;
    }
  }

  .coverWrapper {
    .blurredCoverWrapper();
    z-index: -1;
    align-self: center;
    width: 100%;
    height: 180px;
    .minimalGenericCoverEntrance();

    .cover {
      width: 100%;
      object-fit: cover;
    }
  }

  .profile {
    z-index: 2;
    width: var(--profile-image-size);
    height: var(--profile-image-size);
    margin-top: 24px;
    line-height: 2.14;
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.08));
    border-radius: 50%;
    .minimalGenericProfileEntrance();
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: left;
  }

  .bio {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    text-align: start;
    text-transform: uppercase;
  }
}

//
// Creative
//

.creative-01 {
  @keyframes creative-01-cover-entrance {
    from {
      height: 0;
    }

    to {
      height: 168px;
    }
  }

  @keyframes creative-01-profile-entrance {
    from {
      visibility: hidden;
      transform: translateY(50px);
    }

    to {
      visibility: visible;
      transform: translateY(0);
    }
  }

  @keyframes creative-01-animate-text {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .flexColumnCenter();
  padding-bottom: 10px;

  &.hasCoverPhoto,
  &.hasPlaceholder {
    .profileWrapper {
      margin-top: -100px; // 168 px from the cover height - 68px of the above space we should leave
    }
  }

  &:not(.hasCoverPhoto, .hasPlaceholder) {
    .profileWrapper {
      margin-top: 56px;
    }
  }

  img {
    .fitImage();
  }

  .coverWrapper {
    .blurredCoverWrapper();
    z-index: -1;
    height: 168px;

    .cover,
    .blurredCover {
      animation: creative-01-cover-entrance 0.5s ease-in-out backwards;
    }
  }

  .profileWrapper {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 52px;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .profileFrame {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 156px;
      height: 200px;
      padding: 10px 8px 36px;
      background-image: data-uri('public/header/creative-01/polaroid.png');
      background-repeat: no-repeat;
      animation: creative-01-profile-entrance 0.5s 0.1s ease-in-out backwards;

      &.copy {
        &:first-child {
          animation: creative-01-profile-entrance 0.5s ease-in-out backwards;
          @media (min-width: 460px) {
            position: absolute;
            left: -100px;
          }
        }

        &:last-child {
          animation: creative-01-profile-entrance 0.6s 0.1s ease-in-out
            backwards;
          @media (min-width: 460px) {
            position: absolute;
            right: -100px;
          }
        }
      }
    }
  }

  &:not(.inPreviewMode) {
    .profileWrapper {
      .profileFrame.copy {
        @media (min-width: 991px) {
          &:first-child,
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .name,
  .bio {
    font-weight: var(--primary-font-weight);
    animation: creative-01-animate-text 0.5s 0.2s ease-in-out backwards;
    .containerGutter();
  }

  .name {
    margin-top: 30px;
    font-size: 30px;
    line-height: 1.16;
    letter-spacing: -1.5px;
  }

  .bio {
    margin-top: 3px;
    font-size: 14px;
    line-height: 1.42;
    text-transform: uppercase;
    letter-spacing: -0.25px;
  }
}

.creative-02 {
  @keyframes name-bio-entrance {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeRotateMove(@id; @rotationDegrees; @translationXValue; @translationYValue; @animationDelay;) {
    @keyframes @id {
      from {
        opacity: 0;
        transform: rotate(0) translate(0, 0);
      }

      to {
        opacity: 1;
        transform: rotate(@rotationDegrees)
          translate(@translationXValue, @translationYValue);
      }
    }
    animation: @id 0.4s ease-out @animationDelay backwards;
  }

  .flexColumnCenter();
  padding-bottom: 14px;
  text-align: center;

  &.hasCoverPhoto&.hasProfilePhoto,
  &.hasPlaceholder {
    .imagesWrapper {
      height: 436px;
    }
  }

  img {
    .fitImage();
  }

  .placeholder {
    border: none;
  }

  .name,
  .bio {
    .containerGutter();
  }

  .name {
    margin-top: 24px;
    font-size: 42px;
    line-height: 1.1;
    animation: name-bio-entrance 0.4s ease-out;
  }

  .imagesWrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 44px;
    overflow: hidden;
    filter: drop-shadow(0px 27px 62px rgba(0, 0, 0, 0.4));

    // Fix for Safari animiation flicker
    transform: translateZ(0);

    .cover,
    .profile {
      position: relative;
      box-sizing: border-box;
      background-image: data-uri('public/header/creative-02/photo-frame.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .cover {
      position: absolute;
      top: 54px;

      &:nth-of-type(1) {
        z-index: 1;
        width: 221px;
        height: 316px;
        padding: 14px 3px 11px 4px;

        transform: rotate(-0.431deg) translate(-53px, -39.5px);
        .fadeRotateMove(first, -0.431deg,-53px, -39.5px, 0.4s);
      }

      &:nth-of-type(2) {
        z-index: 2;
        width: 219px;
        height: 314px;
        padding: 14px 3px 11px 4px;

        transform: rotate(6deg) translate(62px, 0);
        .fadeRotateMove(second, 6deg, 62px, 0, 0.425s);
      }

      &:nth-of-type(3) {
        z-index: 3;
        width: 192px;
        height: 275px;
        padding: 12px 3px 9px 3px;
        transform: rotate(-12.46deg) translate(-100px, 0);
        .fadeRotateMove(third, -12.46deg, -100px, 0, 0.45s);
      }

      &:nth-of-type(4) {
        z-index: 4;
        width: 197px;
        height: 282px;
        padding: 13px 3px 9px 4px;
        transform: rotate(30deg) translate(100px, 10px);
        .fadeRotateMove(fourth, 30deg, 100px, 10px, 0.475s);
      }
    }

    .profile {
      z-index: 5;
      width: 243px;
      height: 348px;
      padding: 16px 4px 12px 5px;
      .genericFadeIn();
      animation-fill-mode: backwards;
    }
  }

  .bio {
    margin-top: 28px;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    opacity: 0.7;
    animation: name-bio-entrance 0.4s ease-out 0.4s backwards;
  }
}

.creative-03 {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .flexColumnCenter();
  position: relative;
  padding-bottom: 10px;
  overflow: hidden;
  text-align: center;

  &:not(.hasCoverPhoto):not(.hasProfilePhoto) {
    .name {
      margin-top: 22px;
    }
  }

  img {
    .fitImage();
  }

  .placeholder {
    border: none;
  }

  .bgMask {
    transform: translate(40%, 10%);
  }

  .fgMask {
    transform: translate(-15%, 0);
  }

  .coverWrapper {
    position: absolute;
    left: 50%;
    animation: fadeIn 0.5s ease-out;

    &.bg {
      top: -75px;
      width: 850px;
      height: 440px;
      margin-left: -400px;
      transform: translateZ(0);

      .cover {
        &.placeholder {
          clip-path: path(
            'M59.6,136C24.9,136,0.3,92.9,0.3,59.3C0.3,25.8,71.9,0,106.6,0c34.7,0,34.7,64.7,34.7,98.3v0.1 C141.3,131.9,94.3,136,59.6,136z M230.8,62.5c8.4,8.7,5,24.7-5.5,30.7c-2.5,1.4-5.2,2.2-8.4,2.2c-9.7,0-15.5-14.2-15.5-23c0-8.8,10.8-14,20.5-14 C225.3,58.4,228.4,60.1,230.8,62.5z M560.8,99.5c8.4,8.7,5,24.7-5.5,30.7c-2.5,1.4-5.2,2.2-8.4,2.2c-9.7,0-15.5-14.2-15.5-23c0-8.8,10.8-14,20.5-14 C555.3,95.4,558.4,97.1,560.8,99.5z M529.6,288.4c-34.7,0-59.3-43.1-59.3-76.7s71.5-59.3,106.3-59.3c34.7,0,34.7,64.7,34.7,98.3v0.1 C611.3,284.4,564.3,288.4,529.6,288.4z M546.8,318.5c8.4,8.7,5,24.7-5.5,30.7c-2.5,1.4-5.2,2.2-8.4,2.2c-9.7,0-15.5-14.2-15.5-23 c0-8.8,10.8-14,20.5-14C541.3,314.4,544.4,316.1,546.8,318.5z M260.3,204.6c-52.6,16.2-84.9,67.9-72.1,115.4C201,367.6,254,393,306.6,376.8c52.6-16.2,84.9-67.9,72.1-115.4 S312.9,188.4,260.3,204.6z M222.2,212.2c10.5-6,14-22,5.5-30.7c-2.4-2.5-5.4-4.1-9-4.1c-9.7,0-20.5,5.2-20.5,14c0,8.8,5.8,23,15.5,23 C217,214.4,219.8,213.6,222.2,212.2z M486.2,93.2c10.5-6,14-22,5.5-30.7c-2.4-2.5-5.4-4.1-9-4.1c-9.7,0-20.5,5.2-20.5,14c0,8.8,5.8,23,15.5,23 C481,95.4,483.8,94.6,486.2,93.2z M728.3,32.2c-52.6,16.2-84.9,67.9-72.1,115.4c12.8,47.5,65.8,72.9,118.4,56.7c52.6-16.2,84.9-67.9,72.1-115.4 C833.9,41.4,780.9,16,728.3,32.2z'
          );
        }
      }
    }

    &.fg {
      top: -55px;
      z-index: 3;
      width: 331px;
      height: 384px;
      margin-left: -171px;

      .cover {
        &.placeholder {
          clip-path: path(
            'M192.418 30.657C200.098 44.7022 193.859 61.552 181.968 72.2684C166.905 85.8431 148.665 95.6552 123.264 95.6552C87.5571 95.6552 66 59.0388 66 36.1938C66 13.3489 106.029 0 141.736 0C165.834 0 183.487 14.3221 192.418 30.657ZM237.603 334.739C245.765 325.581 260.276 325.071 270.469 331.821C275.169 334.933 278.772 339.13 280.446 345.133C283.856 357.365 268.225 369.751 257.187 372.87C246.15 375.99 235.878 364.099 232.468 351.867C230.74 345.668 233.26 339.612 237.603 334.739ZM29.6596 149.021C46.4396 149.021 68 136.574 68 118.959C68 101.343 65.9715 77.5312 49.1915 77.5312H49.1405C32.3459 77.5312 0 77.5312 0 95.1287C0 112.744 12.8795 149.021 29.6596 149.021Z'
          );
        }
      }
    }

    .placeholder {
      background-color: #e8e8e8;
    }
  }

  .mainImageWrapper {
    position: relative;
    z-index: 2;
    width: 319px;
    height: 317px;
    margin-top: 16px;
    animation: fadeIn 0.4s ease-out;

    clipPath {
      transform: translate(-10.5%, -10.5%);
    }

    .mainImage {
      width: 100%;

      &.placeholder {
        clip-path: path(
          'M303.5 137.235C303.5 181.019 294.27 219.592 274.172 247.206C254.092 274.796 223.123 291.5 179.5 291.5C135.841 291.5 91.0734 276.269 57.2294 250.11C23.3888 223.953 0.5 186.9 0.5 143.245C0.5 99.5385 17.3091 63.8647 45.0067 39.1127C72.71 14.3558 111.336 0.5 155 0.5C198.678 0.5 235.799 12.8607 261.995 36.1087C288.182 59.35 303.5 93.5157 303.5 137.235Z'
        );
      }
    }
  }

  .name,
  .bio {
    .containerGutter();
  }

  .name {
    margin-top: 54px;
    font-size: 32px;
    line-height: 1.2;
    animation: fadeIn 0.3s ease-out 0.2s both;
  }

  .bio {
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    opacity: 0.7;
    animation: fadeIn 0.3s ease-out 0.3s both;
  }
}

.creative-04 {
  @keyframes name-bio-entrance {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .flexColumnCenter();
  padding-bottom: 24px;

  .placeholder {
    border: none;
  }

  .name,
  .bio {
    .containerGutter();
    animation: name-bio-entrance 0.4s ease-out backwards;
  }

  .name {
    margin-top: 16px;
    font-size: 36px;
    line-height: 1.16;
  }

  .bio {
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.42;
    text-transform: uppercase;
    animation-delay: 0.1s;
  }

  img {
    .fitImage();
  }

  .photoWrapper {
    z-index: 2;
    display: flex;
    gap: 52px;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: hidden;

    &.animate {
      .photoFrame {
        position: relative;
        overflow: hidden;

        .profile {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.6s ease-out !important;

          &.visible {
            opacity: 1;
          }
        }

        &::after {
          left: 0;
          z-index: 3;
          content: '';
          background-color: rgba(255, 255, 255, 0.2);
        }

        .misplacedProfile {
          left: -2px;
          z-index: 2;
        }

        &.animating {
          &::after,
          .misplacedProfile {
            .tvMaskEffect();
          }
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          background: data-uri('public/header/creative-04/texture.png') repeat;
        }
      }
    }

    .photoFrame {
      box-sizing: border-box;
      --photo-clip-path: path(
        'M201.463 3.42C213.069 4.22419 222.819 11.7415 225.148 23.0784C228.19 37.8846 231.5 62.03 231.5 97C231.5 131.971 228.19 156.117 225.147 170.923C222.818 182.259 213.07 189.776 201.464 190.581C182.841 191.872 152.033 193.505 115.418 193.5C79.2413 193.495 48.9121 191.878 30.5117 190.593C18.9203 189.784 9.18575 182.272 6.85793 170.949C3.81434 156.146 0.500009 131.992 0.500011 97C0.500013 62.0072 3.8145 37.853 6.85815 23.0494C9.18591 11.7277 18.9197 4.21573 30.5106 3.406C48.9118 2.12051 79.243 0.503385 115.418 0.5C152.029 0.496575 182.838 2.12958 201.463 3.42Z'
      );
      flex-shrink: 0;
      width: 232px;
      height: 194px;
      clip-path: var(--photo-clip-path);
      .genericFadeIn();
      animation-fill-mode: backwards;
      animation-delay: 0.5s;

      &:first-child {
        animation-delay: 0.3s;
      }

      &:last-child {
        animation-duration: 0.3s;
        animation-delay: 0.6s;
      }

      .photo {
        width: 232px;
        height: 194px;
        clip-path: var(--photo-clip-path);
      }
    }
  }
}

//
// Bold
//

.bold-01 {
  --profile-height: 348px;
  --cover-height: 420px;

  @keyframes bold-01-cover-entrance {
    0% {
      top: calc(var(--cover-height) * -1);
    }

    100% {
      top: 0;
    }
  }
  @keyframes bold-01-profile-entrance {
    0% {
      top: var(--profile-height);
      opacity: 0;
    }

    1% {
      opacity: 1;
    }

    100% {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes bold-01-name-entrance {
    0% {
      transform: translateY(-60px);
    }

    100% {
      transform: translateY(0);
    }
  }
  @keyframes bold-01-bio-entrance {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }

    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .flexColumnCenter();
  padding: 0 12px;
  padding-bottom: 12px;
  overflow: hidden;

  .nameHeading {
    display: flex;
    gap: var(--gap);
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 44px;
    margin-bottom: 25px;
    overflow: hidden;
    font-size: 36px;
    font-weight: normal;
    line-height: 1.12;
    white-space: nowrap;
    border-top: solid var(--biositeColorProfile-text) 1px;
    border-bottom: solid var(--biositeColorProfile-text) 1px;
    animation: bold-01-name-entrance 0.5s 0.2s ease-in-out backwards;
    --gap: 10px;

    @keyframes scrolling-left {
      0% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(calc(-100% - var(--gap)));
      }
    }

    span {
      animation: scrolling-left 5s linear infinite;
    }
  }

  &.hasCoverPhoto,
  &.hasProfilePhoto,
  &.hasPlaceholder {
    .nameHeading {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .photosWrapper {
      position: relative;
      width: 100%;
      max-width: 388px;
      height: var(--cover-height);
      margin-bottom: 20px;
      overflow: hidden;

      .cover {
        position: relative;
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: bold-01-cover-entrance 0.6s ease-in-out backwards;

        &:not(.placeholder) {
          border: 1px solid var(--biositeColorProfile-text);
        }

        &.placeholder {
          background-color: #e8e8e8;
        }
      }

      .profile {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        width: calc(100% - 86px);
        margin: 43px;
        margin-bottom: calc((var(--cover-height) - var(--profile-height)) / 2);

        object-fit: cover;

        &,
        &.placeholder {
          height: var(--profile-height);
          animation: bold-01-profile-entrance 0.5s 0.6s
            cubic-bezier(0.33, 1, 0.68, 1) backwards;
        }
      }
    }
  }

  &:not(.hasCoverPhoto)&:not(.hasPlaceholder) {
    .photosWrapper {
      .profile {
        --profile-height: 100%;
        width: 100%;
        height: var(--profile-height);
        margin-bottom: 0;
        border: 1px solid var(--biositeColorProfile-text);
        animation-delay: 0ms;
      }
    }
  }

  .bio {
    .containerGutter();
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    text-transform: uppercase;
    animation: bold-01-bio-entrance 0.5s 0.5s ease-in-out backwards;
  }
}

.bold-02 {
  .flexColumnCenter();
  padding-bottom: 20px;
  text-align: center;

  &.hasCoverPhoto,
  &.hasPlaceholder {
    .profile {
      margin-top: -208px;
      filter: drop-shadow(0 40px 80px rgba(0, 0, 0, 0.08));
    }
  }

  .coverWrapper {
    @keyframes bold-02-cover-entrance {
      from {
        opacity: 0;
        transform: translateY(-25px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    z-index: -1;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 232px;
    transform: translateZ(0);
    animation: bold-02-cover-entrance 0.4s 0.2s ease-out backwards;
    .blurredCoverWrapper();

    .placeholder {
      background-color: #e8e8e8;
    }
  }

  img {
    .fitImage();
  }

  .name,
  .bio {
    .genericFadeIn();
    animation-delay: 0.2s;
    .containerGutter();
  }

  .profile {
    --horizontal-margin: 22px;
    z-index: 2;
    width: calc(100% - 2 * var(--horizontal-margin));
    max-width: 342px;
    height: 448px;

    margin-top: 24px;
    object-fit: cover;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    transform: translateZ(0);
    .boldGenericProfileEntrance();
  }

  .name {
    margin: 28px 0 0;
    font-size: 48px;
    line-height: 1.2;
  }

  .bio {
    margin: 8px 0 0;
    font-size: 11px;
    line-height: 1.64;
    text-transform: uppercase;
  }
}

.bold-03 {
  @keyframes bold-03-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  max-width: 600px;
  padding-bottom: 20px;
  margin: auto;

  &:not(.hasProfilePhoto) {
    // @TODO: change the color to the respective color theme variable
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.hasPlaceholder {
    border: none;
  }

  img {
    display: block;
    .fitImage();
  }

  .cover {
    position: relative;
    z-index: -1;
    height: 140px;
    clip-path: border-box;
    animation: bold-03-fade-in 0.4s cubic-bezier(0.33, 1, 0.68, 1) backwards;

    .coverFixed {
      position: fixed;
      width: 100%;
      max-width: 600px;
      height: 140px;
      background-position: center;
      background-size: cover;
      // Fixes clip-path bug causing cover to disappear if you scroll down in editor preview, navigate to another panel, and scroll back to top in preview
      transform: skewY(0deg);
    }
  }

  .profile {
    position: sticky;
    top: 0;
    height: auto;
    aspect-ratio: 1;
    animation: bold-03-fade-in 0.4s 0.2s cubic-bezier(0.33, 1, 0.68, 1)
      backwards;
  }

  .info {
    .containerGutter();
    position: sticky;
    top: 0;
    padding-top: 120px;
    padding-bottom: 152px;
    margin: auto;
    text-align: start;
  }

  .name {
    margin: 0;
    font-size: 38px;
    line-height: 1.05;
    animation: bold-03-fade-in 0.4s 0.1s cubic-bezier(0.33, 1, 0.68, 1)
      backwards;
  }

  .bio {
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.5;
    text-transform: uppercase;
    animation: bold-03-fade-in 0.4s 0.1s cubic-bezier(0.33, 1, 0.68, 1)
      backwards;
  }
}

.bold-04 {
  --marquee-gap: 1rem;

  padding-bottom: 10px;

  @keyframes marqueeScroll {
    from {
      transform: translate3d(-100%, 0, 0);
    }

    to {
      transform: translate3d(calc(-200% - var(--marquee-gap)), 0, 0);
    }
  }

  .profileEntranceAnimation {
    @keyframes profileEntrance {
      from {
        opacity: 0;
        transform: scale(0.8);
      }

      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    animation: profileEntrance 0.4s 0.3s ease-out backwards;
  }

  img {
    .fitImage();
  }

  .mainContent {
    position: relative;
    width: 100%;
    height: 716px;
  }

  .coverWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    .cover {
      .genericFadeIn();
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      content: '';
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }

  .marqueeContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    .genericFadeIn();

    .marquee {
      display: flex;
      column-gap: var(--marquee-gap);
      width: 100%;
      overflow: hidden;

      &:nth-child(even) {
        .name {
          animation-direction: reverse;
        }
      }

      each(range(1, 7), {
        &:nth-child(@{value}) {
          @animationDuration: (27 + abs(@value - 4) * 10);

          .name {
            animation-duration: ~"@{animationDuration}s";
          }
        }
      });

      .name {
        position: relative;
        z-index: 2;
        flex-shrink: 0;
        height: 92px;
        margin: 0;
        font-family: var(--primary-font);
        font-size: 96px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        animation: marqueeScroll 5s linear infinite;
      }
    }
  }

  &:not(.inPreviewMode) {
    .marquee {
      @media (min-width: 768px) {
        &:nth-child(1),
        &:nth-child(7) {
          opacity: 0.05;
        }

        &:nth-child(2),
        &:nth-child(6) {
          opacity: 0.3;
        }

        &:nth-child(3),
        &:nth-child(5) {
          opacity: 0.7;
        }
      }
    }
  }

  .profileWrapper {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    .profileEntranceAnimation();

    clipPath {
      transform: translate(-9%, -8%) scale(1.35);
    }
  }

  .profile {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 276px;
    height: 332px;
    overflow: visible;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.12);

    &:not(.placeholder) {
      transform: translate(-50%, -50%);
    }

    &.placeholder {
      z-index: 5;
      transform: translate(-50%, -50%);
    }
  }

  .bio {
    .containerGutter();
    margin: 32px auto 0;
    font-size: 11px;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }
}
