@import 'BioSite/styles/vars';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--biositeColorProfile-buttonBackground);
  border-radius: @sectionBorderRadius;
  box-shadow: @sectionShadow;
}

.sectionImage {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: @sectionImageMaxHeight;
  overflow: hidden;

  img {
    width: auto;
    max-height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @sectionContentPadding;
}

.cta {
  width: 100%;
  margin-top: 2rem;
}
