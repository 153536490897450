@import './../../../../../styles/template/common/mixins.less';

.floatContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.45) 100%
  );
  transition: bottom 400ms;

  &.hidden {
    bottom: -60px;
  }
}

.floatButton {
  width: 220px;
  height: 40px;
  color: var(--biositeColorProfile-buttonText);
  background: var(--biositeColorProfile-buttonBackgroundSolid);
  border-radius: 9999px;

  .arrow {
    right: 12px;
    animation: none;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
  padding: 0 12px;
  font-family: 'Clarkson', sans-serif;
  font-weight: normal;
  color: inherit;
  text-align: left;
}

.buttonText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: max-content;

  svg {
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 17px;
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.arrow {
  position: relative;
  right: 10px;
  width: 10px;
  .slideInMixin();
  animation: slidein 3s infinite 0.5s;

  svg {
    display: block;
    flex-shrink: 0;
    width: 20px;
    height: 17px;
  }
}
