.buttonsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;

  .error {
    margin-top: 20px;
    line-height: 1.2;
    text-align: center;

    .message {
      display: inline-block;
      padding-left: 30px;
      font-size: 12px;
      font-weight: 500;
      color: var(--biositeColorProfile-text);
      background: data-uri('public/icons/common/error.svg') no-repeat left;
    }
  }
}
