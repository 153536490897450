@import 'BioSite/styles/template/common/common.less';

.title {
  min-height: 2.2rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--biositeColorProfile-buttonText);
  word-break: break-word;
  user-select: none;
}

.description {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--biositeColorProfile-buttonTextDim);
  word-break: break-word;
}

.productPrice {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--biositeColorProfile-buttonText);

  span {
    display: flex;
    align-items: center;
  }

  svg {
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 0.5rem;
  }
}
