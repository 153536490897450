@import 'BioSite/styles/template/common/colors';
@import 'BioSite/styles/vars';

@embedHeight: 73.9rem;
@embedWidth: 32.5rem;

.embed_social {
  .facebookEmbed,
  .instagramEmbed,
  .pinterestEmbed,
  .telegramEmbed,
  .tiktokEmbed,
  .twitterEmbed {
    width: 100%;
    min-width: @embedWidth;
    max-width: @sectionMaxWidth;
  }

  .facebookEmbed :global(.fb-post) {
    width: 100% !important;
    overflow: hidden;
    border-radius: @sectionBorderRadius;

    > span {
      width: 100% !important;

      > iframe {
        width: 100% !important;
      }
    }
  }

  .instagramEmbed {
    position: relative;

    :global(iframe.instagram-media) {
      min-width: 100% !important;
      margin: 0 !important;
    }
  }

  .pinterestEmbed {
    > span {
      min-width: inherit !important;
      max-width: inherit !important;
      border-radius: @sectionBorderRadius !important;
    }
  }

  .tiktokEmbed :global(.tiktok-embed) {
    width: @embedWidth;
    min-height: @embedWidth;
    margin: 0 auto;

    > section {
      padding: 0;
    }

    > iframe {
      border-radius: 0.8rem; // specific to tiktok
    }
  }

  .twitterEmbed :global(.twitter-tweet) {
    float: none !important;
    margin: 0 !important;
  }
}

.embed_preview {
  padding: 1.5rem;
  text-align: left;
  background: #fff;
  border: 0.1rem dashed #979797;
  border-radius: 0.25rem;
  box-shadow: @sectionShadow;

  .title {
    display: flex;
    font-size: 1.4rem;
    line-height: 3.6rem;
    color: #4d4d4d;

    img {
      height: 3.6rem;
    }
  }

  .description {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.4rem;
    color: #bdbdbd;
  }

  &.instagram {
    border-color: @INSTAGRAM_COLOR;

    .title {
      color: @INSTAGRAM_COLOR;
    }
  }

  &.tiktok {
    border-color: @TIKTOK_COLOR;

    .title {
      color: @TIKTOK_COLOR;
    }
  }

  &.twitter {
    border-color: @TWITTER_COLOR;

    .title {
      color: @TWITTER_COLOR;
    }
  }

  &.facebook {
    border-color: @FACEBOOK_COLOR;

    .title {
      color: @FACEBOOK_COLOR;
    }
  }

  &.telegram {
    border-color: @TELEGRAM_COLOR;

    .title {
      color: @TELEGRAM_COLOR;
    }
  }

  &.pinterest {
    border-color: @PINTEREST_COLOR;

    .title {
      color: @PINTEREST_COLOR;
    }
  }
}
