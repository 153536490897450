@import (reference) '../mixins.less';

:global(.body_section.links.advanced-08) {
  filter: drop-shadow(0 6px 15px rgba(0, 0, 0, 0.06));
  @media (max-width: 990px) {
    padding: 10px 0;
  }
}

// Repeat the previews rules because in preview the media query is not true

:global(.body_section.links.advanced-08.isPreviewMode) {
  padding: 10px 0;
}

.advanced-08 {
  .advanced-link(none);
  position: relative;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
  justify-content: space-between;
  width: 280px;
  min-height: 157px;
  padding: 16px 12px;
  border-radius: 8px;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform: scale(0.8) translateZ(0);

  &:not(.isLast) {
    margin-right: 8px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  &.isVisible {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }

  &.hasThumbnail {
    color: var(--biositeColorProfile-linkThumbnailText);
  }

  .thumbnailWrapper {
    .thumbnail-overlay();

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.isIcon {
        object-fit: contain;
      }
    }

    .icon {
      width: 60%;
    }
  }

  .name {
    z-index: 2;
    font-size: 14px;
    line-height: 1.21;
  }

  svg {
    z-index: 2;
    flex-shrink: 0;
  }
}
