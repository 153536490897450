.variant5_container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-family: 'Clarkson', sans-serif;
  font-weight: normal;
  color: inherit;
  text-align: left;

  .button_text {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    margin-right: auto;
    margin-left: 15px;
  }
}
