@import 'BioSite/styles/vars';
@dot-top-position: 0.7rem;

.date {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
  aspect-ratio: 1;
  font-family: 'Clarkson', sans-serif;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 50%;

  &.available {
    font-weight: 600;
    color: #2b2b2b;
    background-color: #fff;
    box-shadow: @buttonShadow;
  }

  &.selected {
    border: 0.2rem solid #000000;

    .todayDot {
      top: calc(@dot-top-position - 0.2rem);
    }
  }

  &.disabled {
    cursor: default;
  }

  .todayDot {
    position: absolute;
    top: @dot-top-position;
    left: 50%;
    width: 0.4rem;
    aspect-ratio: 1;
    background-color: #000000;
    border-radius: 50%;
    transform: translateX(-50%);
  }
}
