@import 'BioSite/styles/vars';

.embed_music {
  iframe {
    display: block;
    border: none;
    box-shadow: @sectionShadow;

    &.spotify {
      height: 8rem;
    }

    &.soundcloud {
      height: 17.1rem;
    }
  }
}
