@import 'BioSite/styles/modals.less';
@import 'BioSite/styles/vars.less';
@import 'BioSite/styles/sizes.less';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  padding: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: @overlay-background;
  backdrop-filter: @overlay-backdrop-filter;
}

.modal {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: fit-content;
  background: @modalBackgroundColor;

  .modalMobileWidth();
}
