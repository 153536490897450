.screen {
  @media (max-width: 641px) {
    .scrollContainer {
      padding-right: 22px;
      scroll-padding-left: 22px;
    }

    .previewWrapper {
      &:first-child {
        margin-left: 22px;
      }
    }
  }

  &:not(.isOverflowing) {
    .scrollContainer {
      cursor: default !important;
    }

    .arrow {
      display: none !important;
    }
  }

  &.cards {
    @media (min-width: 991px) {
      border-top: 0.1rem solid #d9d9d9;
    }

    .arrows {
      display: none;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;

      @media (min-width: 991px) {
        display: flex;
      }

      .arrow {
        z-index: 1;
      }
    }
  }

  &.advanced {
    position: relative;

    .arrows {
      display: none;

      @media (min-width: 991px) {
        display: block;
      }

      .arrow {
        position: absolute;
        margin: 0 8px;
        transform: translateY(-50%);

        &.leftArrow {
          right: 100%;
          transform: translateY(-50%) rotate(180deg);
        }

        &.rightArrow {
          left: 100%;
          transform: translateY(-50%);
        }
      }
    }

    &.advanced-07 {
      .arrow {
        top: 55px; // half of the `110px` of the thumbnail square
      }
    }

    &.advanced-08 {
      .arrow {
        top: 73px; // half of the `146px` of the link's minimum height
      }
    }
  }

  // Repeat some previews rules because in preview the media queries are not true

  &.isPreviewMode {
    &.cards {
      border-top: 0;
    }

    &.cards,
    &.advanced {
      .arrows {
        display: none;
      }

      .scrollContainer {
        padding-right: 24px;
        scroll-padding-left: 24px;
      }

      .previewWrapper {
        &:first-child {
          margin-left: 24px;
        }
      }
    }
  }

  &.cards {
    border-top: 0;
  }
}

.arrow {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  color: var(--biositeColorProfile-text);
  cursor: pointer;
  background: none;
  border: 0;

  &.leftArrow {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }

  svg {
    display: block;
  }
}

.scrollContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; // for firefox

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    visibility: var(--click-blocker-visibility, hidden);
    content: '';
  }
}

.filler {
  box-sizing: border-box;
  flex-shrink: 0;

  &:first-child {
    scroll-margin-left: -20vw;
  }
}

.previewWrapper {
  scroll-snap-align: start;
}
