@import 'BioSite/styles/template/common/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .cancelled {
    align-self: center;
    max-width: 24.2rem;
    font-family: 'Clarkson', sans-serif;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: @errorRed;
    text-align: center;
  }
}
