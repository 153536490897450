@import '../styles.less';

.stripeButton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  font-size: 16px;
  font-weight: 500;
  color: var(--biositeColorProfile-buttonTextText);
  text-transform: capitalize;
  cursor: pointer;
  background: var(--biositeColorProfile-buttonText);
  border: none;
  border-radius: 6px;

  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cardIcon {
    display: flex;
    margin-right: 10px;
    color: var(--biositeColorProfile-buttonTextText);
  }

  &.disabled {
    cursor: default !important;
    background-color: var(--biositeColorProfile-buttonTextDim) !important;
    border-color: var(--biositeColorProfile-buttonTextDim) !important;
  }
}
