.variant7_container {
  position: relative;
  align-items: center;
  height: 100%;

  .text {
    margin-bottom: -8px;
    font-family: 'Clarkson', sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: var(--biositeColorProfile-text);
    text-transform: uppercase;
  }

  .spinningCircleWrapper {
    position: absolute;
    top: 23px;
    left: 32px;
  }
}
