.notificationBanner {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  opacity: 0;
  transition:
    transform 400ms,
    opacity 400ms;
  transform: translateY(-120px);

  &.active {
    opacity: 1;
    transition-delay: 500ms;
    transform: translateY(0);
  }

  .notification {
    max-width: 500px;
    padding: 12px 8px;
    margin-top: 20px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
  }

  &.enter {
    opacity: 0;
    transform: scale(0.95);
  }

  &.enter-active {
    opacity: 1;
    transition:
      opacity 200ms,
      transform 200ms;
    transform: scale(1);
  }

  &.exit {
    height: 46px;
    opacity: 1;
    transform: scale(1);
  }

  &.exit-active {
    height: 0;
    margin-top: 0;

    opacity: 0;
    transition:
      opacity 200ms,
      transform 200ms,
      height 200ms,
      margin-top 200ms;
    transform: scale(0.95);
  }
}
