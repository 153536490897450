@keyframes loader {
  0% {
    background-color: rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.loader {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
  color: #fff;
  background-color: #fff;
  border-radius: 5px;
  animation: loader 0.5s 0.25s infinite linear alternate;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 5px;
    height: 5px;
    color: #fff;
    content: '';
    background-color: #fff;
    border-radius: 5px;
  }

  &::before {
    left: -10px;
    animation: loader 0.5s infinite alternate;
  }

  &::after {
    left: 10px;
    animation: loader 0.5s 0.5s infinite alternate;
  }
}
