@import (reference) '../mixins.less';

.advanced-03 {
  .advanced-link();
  min-height: 652px;
  padding: 22px;
  border-radius: 24px;
  border-bottom-right-radius: 0;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform: perspective(800px) rotate3d(0, 1, 0, 10deg);

  &.isVisible {
    opacity: 1;
    transform: perspective(800px);
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  .header {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1.4;
    text-transform: uppercase;

    .truncatedName {
      display: -webkit-box;
      max-height: 30px; // Graceful degradation
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .thumbnailWrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: 69px;
    margin-bottom: 69px;

    .thumbnail {
      width: 333px;
      max-width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .footer {
    display: flex;
    gap: 9px;
    align-items: flex-end;
    justify-content: space-between;

    .footerText {
      min-width: 0;
    }

    svg {
      flex-shrink: 0;
      margin-bottom: 6px;
    }
  }

  .name {
    font-size: 18px;
    line-height: 1.31;
  }

  .url {
    display: block;
    width: 100%;
    overflow: hidden;
    font-size: 10px;
    line-height: 2.2;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
