.post {
  &.post_enter {
    opacity: 0;
  }

  &.post_enter_active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.post_exit_active {
    opacity: 1;
  }

  &.post_exit {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .container {
    position: relative;
    display: flex;

    @supports not (aspect-ratio: 1 / 1) {
      padding-top: 100%;
    }

    .img {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;

      @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.tiktok .img {
      aspect-ratio: 9 / 16;

      @supports not (aspect-ratio: 9 / 16) {
        height: 56.25%;
      }
    }

    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 18px;
      height: 18px;
      background: data-uri('public/icons/common/link.svg') no-repeat center;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 2px;
    }
  }
}
