@import (reference) '../mixins.less';

.cards {
  .button-reset();
  box-sizing: border-box;

  .overlay {
    width: 100%;
  }

  .name {
    overflow-wrap: anywhere;
  }
}

.thumbnailOverlayMixin {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      var(--biositeColorProfile-linkThumbnailBackground) 100%
    );
  }
}
