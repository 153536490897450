@desktop-breakpoint-min: 64em; // 1024px
@mobile-breakpoint-max: 63.938em; // 1023px
@tablet-breakpoint-min: 50em; // 800px

@desktop: ~'(min-width: @{desktop-breakpoint-min})';
@tablet: ~'(max-width: @{mobile-breakpoint-max}) and (min-width: @{tablet-breakpoint-min})';
@mobile: ~'(max-width: @{mobile-breakpoint-max})';
@mobileNoTablet: ~'(max-width: @{tablet-breakpoint-min})';
@mobile-max-width-screen: 48.5rem;

.modalMobileWidth() {
  width: @mobile-max-width-screen;

  @media @mobileNoTablet {
    width: 100%;
  }
}
