.fitImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blurredCoverWrapper {
  position: relative;
  box-sizing: border-box;
  align-self: stretch;

  &::after {
    // Need to add the blur via `backdrop-filter` to prevent a weird faded
    // border-like effect in Safari (and it must have the `-webkit` prefix)
    @media (min-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: '';
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }

  .cover {
    height: 100%;

    @media (min-width: 991px) {
      position: relative;
      z-index: 2;
      max-width: 991px;
    }
  }

  .blurredCover {
    display: none;

    @media (min-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      transform: scaleX(-1);
    }
  }
}

.containerGutter {
  box-sizing: border-box;
  max-width: 600px;
  padding: 0 24px;
  margin: 0;
  overflow-wrap: break-word;
}

.placeholderContent {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #e8ebea;
  border: 1px dashed #979797;
}

//
// Entrance animations
//

.minimalGenericProfileEntrance {
  @keyframes minimalProfileEntrance {
    from {
      opacity: 0.6;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  animation: minimalProfileEntrance 0.4s ease-out;
}

.minimalGenericCoverEntrance {
  @keyframes minimalCoverEntrance {
    from {
      opacity: 0.6;
      transform: translateY(-25px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: minimalCoverEntrance 0.4s ease-out;
}

.boldGenericProfileEntrance {
  @keyframes boldProfileEntrance {
    from {
      transform: scale(0.8) translateZ(0);
    }

    to {
      transform: scale(1) translateZ(0);
    }
  }

  animation: boldProfileEntrance 0.4s 0.1s ease-out backwards;
}

.genericFadeIn {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.4s ease-out backwards;
}

.genericFadeInWithDelay {
  @keyframes fadeInWithDelay {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  animation: fadeInWithDelay 0.4s 0.1s ease-out backwards;
}

// Loop animations
//

.tvMaskEffect {
  @keyframes tvMaskEffectAnimation {
    0% {
      opacity: 0.2;
      -webkit-mask-position: 0% -30%;
      mask-position: 0% -30%;
    }

    15% {
      opacity: 1;
    }

    100% {
      -webkit-mask-position: 0% 200%;
      mask-position: 0% 200%;
    }
  }

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,

    #fff 2%,
    #fff 13%,

    transparent 15%,
    transparent 23%,

    #fff 25%,
    #fff 28%,

    transparent 30%,
    transparent 43%,

    #fff 45%,
    #fff 68%,

    transparent 70%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,

    #fff 2%,
    #fff 13%,

    transparent 15%,
    transparent 23%,

    #fff 25%,
    #fff 28%,

    transparent 30%,
    transparent 43%,

    #fff 45%,
    #fff 68%,

    transparent 70%
  );
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: 0% -30%;
  mask-position: 0% -30%;
  -webkit-mask-size: 100% 200%;
  mask-size: 100% 200%;

  transform: skewY(-1deg);
  animation: tvMaskEffectAnimation 3.5s linear infinite forwards !important;
  animation-delay: 500ms;
}
