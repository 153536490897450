@calendar-row-gap: 0.8rem;

.calendarWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.monthNavigation {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2.1rem;
  font-family: 'Clarkson', sans-serif;

  span {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-transform: uppercase;
  }

  :last-child {
    display: flex;
    gap: 2.5rem;

    .arrow {
      gap: 2.5rem;
      padding: 0.5rem;
      margin: -0.5rem;

      svg {
        width: 0.775rem;
        height: 1.375rem;
      }

      &.back {
        transform: rotate(-180deg);
      }

      &.hidden {
        cursor: default;
        opacity: 0;
      }
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: @calendar-row-gap;
  justify-items: center;

  .weekday {
    margin-bottom: calc(2.2rem - @calendar-row-gap);
    font-family: 'Clarkson', sans-serif;
    line-height: 1rem;
    color: var(--biositeColorProfile-textDim);
    text-align: center;
    text-transform: uppercase;
  }
}
