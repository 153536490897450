.cta {
  text-transform: uppercase;
}

.payments {
  @sectionMaxWidth: 48.5rem;

  width: 100%;
  max-width: calc(@sectionMaxWidth - calc(2rem * 2));
}
