@import (reference) '../mixins.less';

:global(.body_section.links.advanced-06) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 0;
  grid-gap: 12px;
}

.advanced-06 {
  .advanced-link();
  height: fit-content;
  border-radius: 8px;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform: translateY(20px);

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  &.becameVisible {
    opacity: 1;
    transform: translateY(0);
  }

  .thumbnail {
    max-width: 100%;

    &.isIcon {
      align-self: center;
      max-width: 75%;
    }
  }

  .name {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 24px 16px;
    font-size: 14px;
    line-height: 1.28;
  }
}
