.paymentPresets {
  width: 100%;

  .title {
    display: block;
    margin-top: 2rem;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--biositeColorProfile-buttonText);
    text-align: left;
  }

  .options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;

    div {
      width: if((var(--preset-count) = 2), (33% - 4px), calc(50% - 4px));
      font-size: 14px;
      font-weight: 500;
      color: var(--biositeColorProfile-buttonText);
      text-align: center;

      &.custom {
        flex: 1;
        width: auto;
      }

      label {
        box-sizing: border-box;
        display: block;
        height: 54px;
        padding: 5px;
        line-height: 40px;
        cursor: pointer;
        border: 1px solid var(--biositeColorProfile-buttonTextDim);
        border-radius: 6px;

        input[type='radio'] {
          display: none;
        }

        &:has(input[type='radio']:checked) {
          line-height: 3.8rem;
          border-color: var(--biositeColorProfile-buttonText);
          border-width: 2px;
        }
      }
    }
  }

  .customInput {
    position: relative;

    .prefix {
      position: absolute;
      height: 40px;
      margin-left: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      color: var(--biositeColorProfile-buttonText);
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding-left: 30px;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      color: var(--biositeColorProfile-buttonText);
      background: unset;
      border: 1px solid var(--biositeColorProfile-buttonTextDim);
      border-radius: 6px;

      &::placeholder {
        -webkit-text-fill-color: var(--biositeColorProfile-buttonTextDim);
        color: var(--biositeColorProfile-buttonTextDim);
      }
    }
  }
}
