@import '../styles.less';

.payPalButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 58px;
  cursor: pointer;
  background-color: #f6c657;
  border: none;
  border-radius: 6px;

  .icon {
    width: 25px;
    height: 27px;
    margin-right: 5px;
    background-image: data-uri('public/icons/support/paypal.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .logo {
    width: 64px;
    height: 20px;
    background-image: data-uri('public/logos/paypal-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.disabled {
    cursor: default !important;
    background-color: var(--biositeColorProfile-buttonTextDim) !important;
  }
}
